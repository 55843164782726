import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Modal,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomTextField, OutlineButton } from "../../../common/components";
import { GridCard } from "../../../common/components";
import { getUserStorage } from "../../../common/utils";
import { useEffect, useState } from "react";
import { services } from "../../manager/services";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { constants } from "../../../config/constants";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { CurrencyIcon } from "../../../common/components/Currency/CurrencyIcon";

const access_pages = [
    "Sales",
    "Sales Insights",
    "Sales Aggregators",
    "Sales Performance",
    "Customers",
    "Customer Insights",
    "Customers Data",
    "Menu",
    "Menu Engineering",
    "Like-for-like",
    "Bought Together",
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 10,
    borderRadius: 4,
};
const CreateManager = () => {
    const newUserCost = 49;
    const { t } = useTranslation();

    const [isEdit, setIsEdit] = useState(false);
    const navigate = useNavigate();
    const user = getUserStorage();
    const ownerBranches = user?.merchant?.branches?.map((branch) => ({
        id: branch.branch_id,
        label: branch.name,
        address: branch.address,
        phone: branch.address,
    }));

    const rolesOptions = [2, 3, 4, 5, 6, 7, 8].map((op) => ({
        id: op,
        label: t(`settings.roles_keys.${op}`),
        value: op,
    }));

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [selectedRole, setSelectedRole] = useState(
        rolesOptions.find(
            (roleId) =>
                roleId.id ===
                JSON.parse(sessionStorage.getItem("toBeEditedManager"))?.role
        )
    );

    const [selectedAccess, setSelectedAccess] = useState(
        JSON.parse(sessionStorage.getItem("toBeEditedManager"))?.access?.map(
            (a) => a.type
        ) || []
    );

    const [selectedBranches, setSelectedBranches] = useState(
        JSON.parse(sessionStorage.getItem("toBeEditedManager"))?.branch?.map(
            (b) => b.branch_id
        ) || []
    );

    const { handleSubmit, control } = useForm({
        defaultValues: {
            name: "",
            email: "",
            phone: "",
            ...JSON.parse(sessionStorage.getItem("toBeEditedManager")),
        },
    });

    const { mutate: createManager, isLoading } = useMutation({
        mutationFn: JSON.parse(sessionStorage.getItem("toBeEditedManager"))
            ? services.updateManager
            : services.createManager,
        onSuccess: (res) => {
            if (!res.data.error) {
                toast.success("User Created Sussessfully!", {
                    hideProgressBar: true,
                });
                navigate(-1);
                sessionStorage.removeItem("toBeEditedManager");
            } else {
                if (res.data.code === 401) {
                    handleOpen();
                }
            }
        },
        onError: (err) => console.log(err),
    });

    const onSubmit = (values) => {
        const preValues = values;
        delete preValues.branch_id;
        createManager({
            ...preValues,
            branchs: selectedBranches,
            access: selectedAccess,
            role: selectedRole.id,
        });
    };

    const onCancel = () => navigate(-1);

    const handlePaybranch = () => {
        const addUserLink =
            "https://zohosecurepay.com/checkout/19fgtf9-68nwqb1seivfy/Add-User";

        localStorage.setItem(
            constants.storage.payment_user,
            JSON.stringify({
                isAddUser: true,
            })
        );

        window.open(addUserLink, "_self");
    };

    useEffect(() => {
        if (JSON.parse(sessionStorage.getItem("toBeEditedManager")))
            setIsEdit(true);
        // sessionStorage.removeItem("toBeEditedManager");
    }, []);

    useEffect(() => {
        return () => sessionStorage.removeItem("toBeEditedManager");
    }, []);

    const toggleAccess = (key) => {
        if (selectedAccess.includes(key))
            setSelectedAccess(selectedAccess.filter((k) => k !== key));
        else setSelectedAccess([...selectedAccess, key]);
    };

    const toggleBranch = (key) => {
        if (selectedBranches.includes(key))
            setSelectedBranches(selectedBranches.filter((k) => k !== key));
        else setSelectedBranches([...selectedBranches, key]);
    };

    const handleSelectAll = (key) => {
        if (key === "ALL") {
            setSelectedAccess(access_pages);
        } else {
            setSelectedAccess([]);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack padding={8} spacing={8} minWidth="100%">
                <Stack flexDirection="row" justifyContent="space-between">
                    <Typography variant="h5" fontSize={30} fontWeight="bold">
                        {t("settings.my_team")}
                    </Typography>
                </Stack>

                <Grid container flexWrap="nowrap">
                    <GridCard
                        fullWidth
                        boxStyles={{ minHeight: 420, height: "fit-content" }}
                    >
                        <Stack p={8} spacing={4}>
                            <Stack spacing={1}>
                                <Typography color="#0F172A" fontSize={16}>
                                    {t("settings.manager_info")}
                                </Typography>
                                <Typography color="#6B7280" fontSize={14}>
                                    {t("settings.create_manager_info")}{" "}
                                </Typography>
                            </Stack>

                            <Stack spacing={6}>
                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            {t("settings.manager_name")}
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder={t(
                                                        "settings.prompt_name"
                                                    )}
                                                    variant="outlined"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>

                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            {t("settings.manager_email")}
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder={t(
                                                        "settings.prompt_email"
                                                    )}
                                                    variant="outlined"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>

                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            {t("settings.manager_phone")}
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Controller
                                            name="phone"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder={t(
                                                        "settings.prompt_phone"
                                                    )}
                                                    variant="outlined"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>

                                {/* <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            {t("settings.chose_branch")}
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Autocomplete
                                            size="small"
                                            value={selectedBranch}
                                            onChange={(e, value) =>
                                                setSelectedBranch(value)
                                            }
                                            disablePortal
                                            id="combo-box-demo"
                                            options={ownerBranches}
                                            sx={{ width: 400 }}
                                            disableClearable
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{ height: 32 }}
                                                    label={t(
                                                        "settings.chose_branch"
                                                    )}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: "#6B7280",
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box> */}

                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            {t("settings.role")}
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Autocomplete
                                            size="small"
                                            value={selectedRole}
                                            onChange={(e, value) =>
                                                setSelectedRole(value)
                                            }
                                            disablePortal
                                            options={rolesOptions}
                                            sx={{ width: 400 }}
                                            disableClearable
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    sx={{ height: 32 }}
                                                    label={t("settings.role")}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: "#6B7280",
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>
                                <Divider />

                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            {t("settings.chose_branch")}
                                        </Typography>
                                    </Box>
                                    <FormGroup>
                                        {ownerBranches.map((branch) => (
                                            <FormControlLabel
                                                checked={selectedBranches.includes(
                                                    branch.id
                                                )}
                                                control={<Checkbox />}
                                                label={branch.label}
                                                onChange={() =>
                                                    toggleBranch(branch.id)
                                                }
                                            />
                                        ))}
                                    </FormGroup>
                                </Box>

                                <Divider />

                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            {t("settings.access")}
                                        </Typography>
                                    </Box>
                                    <FormGroup>
                                        <FormControlLabel
                                            checked={
                                                !!selectedAccess.length &&
                                                access_pages.every((x) =>
                                                    selectedAccess.includes(x)
                                                )
                                            }
                                            control={<Checkbox />}
                                            label={t(
                                                `settings.access_keys.all`
                                            )}
                                            onChange={() => {
                                                const isAllSelected =
                                                    !!selectedAccess.length &&
                                                    access_pages.every((x) =>
                                                        selectedAccess.includes(
                                                            x
                                                        )
                                                    );
                                                handleSelectAll(
                                                    isAllSelected
                                                        ? "Deselect"
                                                        : "ALL"
                                                );
                                            }}
                                        />
                                        {access_pages.map((accessPage) => (
                                            <FormControlLabel
                                                checked={selectedAccess.includes(
                                                    accessPage
                                                )}
                                                control={<Checkbox />}
                                                label={t(
                                                    `settings.access_keys.${accessPage.toLowerCase()}`
                                                )}
                                                onChange={() =>
                                                    toggleAccess(accessPage)
                                                }
                                            />
                                        ))}
                                    </FormGroup>
                                </Box>
                            </Stack>
                        </Stack>
                    </GridCard>
                </Grid>
                <Box
                    display="flex"
                    alignItems="center"
                    gap={4}
                    justifyContent="flex-end"
                >
                    <OutlineButton
                        onClick={onCancel}
                        buttonStyles={{ borderRadius: "6px", width: 80 }}
                        buttonProps={{ disabled: isLoading }}
                    >
                        {t("common.cancel")}
                    </OutlineButton>
                    <OutlineButton
                        buttonStyles={{
                            borderRadius: "6px",
                            width: isEdit ? 60 : 80,
                            color: "white",
                            backgroundColor: "#5BCFDE",
                            "&:hover": {
                                border: "1px solid #EEEFF4",
                                backgroundColor: "#5BCFDE",
                            },
                        }}
                        buttonProps={{ type: "submit", disabled: isLoading }}
                    >
                        {isEdit ? t("common.save") : t("common.create")}
                    </OutlineButton>
                </Box>
            </Stack>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack spacing={5}>
                        <Box display="flex" alignItems="center" gap={2}>
                            <WarningAmberIcon sx={{ color: "#FFD700" }} />
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                            >
                                Users Limit Reached
                            </Typography>
                        </Box>

                        <Stack>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                            >
                                You have reached to the maximum number of users
                                on your subscribtion plan!
                            </Typography>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                            >
                                but you can add a new user for {newUserCost}{" "}
                                <CurrencyIcon />
                            </Typography>
                        </Stack>
                    </Stack>

                    <Box
                        display="flex"
                        mt={10}
                        justifyContent="flex-end"
                        gap={5}
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                textTransform: "capitalize",
                                borderRadius: "6px",
                            }}
                            onClick={handleClose}
                        >
                            {t("common.cancel")}
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                textTransform: "capitalize",
                                borderRadius: "6px",
                                width: "fit-content",
                                color: "white",
                                backgroundColor: "#5BCFDE",
                                border: "1px solid #EEEFF4",
                                "&:hover": {
                                    border: "1px solid #EEEFF4",
                                    backgroundColor: "#5BCFDE",
                                },
                            }}
                            onClick={handlePaybranch}
                        >
                            {t("common.proceed")}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </form>
    );
};

export { CreateManager };
