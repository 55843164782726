import { Button, Grid, Stack, Typography, Box, Radio } from "@mui/material";
import { GridCard } from "../../../../common/components";
import { getUserStorage } from "../../../../common/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { constants } from "../../../../config/constants";
import { CurrencyIcon } from "../../../../common/components/Currency/CurrencyIcon";

const ballurh_plans = [
    {
        key: 2,
        name: "Basic",
        monthly_rate: 249,
        branches_users: "1 Branche / 2 users",
        features: [
            "Manage all branches sales.",
            "Assign a Manager to a branch.",
            "View all Sales channel insights in one page (Aggregators).",
            "View all customer’s behavioral insights.",
        ],
        pay_url:
            "https://zohosecurepay.com/checkout/ew52q5i-jh7den0rwmjql/Basic-Subscription",
    },
    {
        key: 3,
        name: "Growth",
        monthly_rate: 449,
        branches_users: "3 Branches / 4 users",
        features: [
            "All the basic features +",
            "Like for like report (Compare branch, category, products)",
            "Menu engineering insights",
            "Frequently bought together (Market Basket Analysis)",
        ],
        pay_url:
            "https://zohosecurepay.com/checkout/y0tn7t8-1gsmfuc83t181/Growth-Subscription",
    },
    {
        key: 4,
        name: "Basic Annual",
        monthly_rate: 219,
        total: 2628,
        branches_users: "1 Branche / 2 users",
        features: [
            "Manage all branches sales.",
            "Assign a Manager to a branch.",
            "View all Sales channel insights in one page (Aggregators).",
            "View all customer’s behavioral insights.",
        ],
        pay_url:
            "https://zohosecurepay.com/checkout/19fgtf9-ydxne8vkyqkaw/Basic-Annual-Subscription",
    },
    {
        key: 5,
        name: "Growth Annual",
        monthly_rate: 395,
        total: 4740,
        branches_users: "3 Branches / 4 users",
        features: [
            "All the basic features +",
            "Like for like report (Compare branch, category, products)",
            "Menu engineering insights",
            "Frequently bought together (Market Basket Analysis)",
        ],
        pay_url:
            "https://zohosecurepay.com/checkout/mb0kr0m-jh7den0rji7ol/Growth-Annual-Subscription",
    },
];

const getCurrentPlan = (id) => {
    return ballurh_plans.find((plan) => plan.key === id);
};

const PaymentDetailsRow = ({ label, value, unit, showDivider = true }) => (
    <>
        <Grid
            item
            xs={12}
            sm={8}
            md={6}
            paddingY={5}
            borderBottom={showDivider && "1px solid #E5E7EB"}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
            >
                <Typography fontSize={14} color="#6B7280">
                    {label}
                </Typography>
                <Box display="flex" alignItems="center" gap={2}>
                    <Typography fontSize={14} color="#111827">
                        {value}
                    </Typography>
                    {unit && (
                        <Typography fontSize={14} color="#94A3B8">
                            {unit}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Grid>
        <Grid item sm={4} md={6} />
    </>
);

const PlanListItem = ({ label }) => (
    <Typography fontSize={14} color="#111827" fontWeight="light">
        <Box display="flex" alignItems="center" gap={3}>
            <Box
                display="block"
                width="5px"
                height="5px"
                backgroundColor="#D1D5DB"
                borderRadius="50%"
            ></Box>
            {label}
        </Box>
    </Typography>
);

const PaymentPlanRow = ({ plan, isSelected, onPlanChange }) => {
    const { t } = useTranslation();

    return (
        <Grid
            item
            xs={12}
            minHeight="150px"
            border={`1px solid ${isSelected ? "#5BCFDE" : "#D1D5DB"}`}
            borderRadius="6px"
            color="#111827"
            backgroundColor={isSelected && "rgba(91,207,222,0.04)"}
            sx={{ cursor: "pointer" }}
            onClick={() => onPlanChange(plan.key)}
        >
            <Grid container paddingX={6} paddingY={4}>
                <Grid item xs={3}>
                    <Box
                        display="flex"
                        gap={1}
                        alignItems="center"
                        paddingY={5}
                    >
                        <Radio
                            checked={isSelected}
                            sx={{
                                "&.Mui-checked": {
                                    color: "#5BCFDE",
                                },
                            }}
                            // onClick={() => onPlanChange(plan.key)}
                        />
                        <Typography fontWeight="bold" fontSize={14}>
                            {plan.name}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={2.5}>
                    <Box
                        display="flex"
                        alignItems="center"
                        gap={2}
                        paddingY={8}
                    >
                        <Typography fontSize={14} color="#111827">
                            {plan.monthly_rate} <CurrencyIcon />
                        </Typography>
                        <Typography fontSize={14} color="#94A3B8">
                            {t("common.permo")}
                        </Typography>
                    </Box>

                    {plan.total && (
                        <Typography
                            fontSize={14}
                            color="#111827"
                            fontWeight="bold"
                        >
                            {t("common.total")}: {plan.total} <CurrencyIcon />
                        </Typography>
                    )}
                </Grid>

                <Grid item xs={2.5}>
                    <Box display="flex" alignItems="center" paddingY={8}>
                        <Typography fontWeight="bold" fontSize={14}>
                            {plan.branches_users}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={4}>
                    <Stack spacing={3} onClick={(e) => e.stopPropagation()}>
                        <Typography fontWeight="bold" fontSize={14}>
                            Included in plan
                        </Typography>

                        <Box display="flex">
                            <Stack spacing={0}>
                                {plan.features.map((label) => (
                                    <PlanListItem label={label} />
                                ))}
                            </Stack>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
};

const MyPlanInfo = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const user = getUserStorage();
    const currentPlan =
        user.merchant.subscription === 1 || user.merchant.subscription === 3
            ? 3
            : 2;

    const [currentPlanId, setCurrentPlanId] = useState(currentPlan);
    const [isUpdatingPlan, setIsUpdatingPlan] = useState(false);

    const currentSubscription = getCurrentPlan(currentPlan);

    const handlePlanChange = (planKey) => {
        setCurrentPlanId(planKey);
    };

    return (
        <GridCard
            fullWidth
            boxStyles={{
                minHeight: "164px",
                height: "fit-content",
                position: "relative",
            }}
        >
            <Stack gap={6}>
                <Stack>
                    <Typography fontSize={20}>Plan</Typography>
                    <Typography fontSize={14} color="#6B7280">
                        Update your plan method.
                    </Typography>
                </Stack>

                <Stack width="100%">
                    <Grid container width="100%">
                        {isUpdatingPlan ? (
                            <Stack spacing={8} width="100%">
                                {ballurh_plans.map((plan) => (
                                    <PaymentPlanRow
                                        plan={plan}
                                        isSelected={plan.key === currentPlanId}
                                        onPlanChange={handlePlanChange}
                                    />
                                ))}

                                <Stack spacing={2}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap={3}
                                    >
                                        <Typography
                                            fontSize={14}
                                            fontWeight="bold"
                                        >
                                            99 <CurrencyIcon />
                                        </Typography>
                                        <Typography
                                            fontSize={14}
                                            color="#6B7280"
                                        >
                                            {t("settings.add_branch")}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap={3}
                                    >
                                        <Typography
                                            fontSize={14}
                                            fontWeight="bold"
                                        >
                                            49 <CurrencyIcon />
                                        </Typography>
                                        <Typography
                                            fontSize={14}
                                            color="#6B7280"
                                        >
                                            {t("settings.add_user")}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Stack>
                        ) : (
                            <PaymentDetailsRow
                                label={`${currentSubscription.name} ${
                                    user.merchant.subscription === 1
                                        ? "(Free Trial)"
                                        : ""
                                }`}
                                value={
                                    <>
                                        {currentSubscription.monthly_rate}{" "}
                                        <CurrencyIcon />
                                    </>
                                }
                                unit={t("common.permo")}
                                showDivider={false}
                            />
                        )}
                    </Grid>
                </Stack>
            </Stack>

            {isUpdatingPlan ? (
                <Box display="flex">
                    <Button
                        variant="text"
                        sx={{
                            textTransform: "capitalize",
                            color: "#383838",
                            position: "absolute",
                            top: 20,
                            right: !isArabic && 90,
                            left: isArabic && 90,
                            fontSize: 14,
                        }}
                        onClick={() => setIsUpdatingPlan(false)}
                        // disabled={currentPlan === currentPlanId }
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="text"
                        sx={{
                            textTransform: "capitalize",
                            color: "#5BCFDE",
                            position: "absolute",
                            top: 20,
                            right: !isArabic && 20,
                            left: isArabic && 20,
                            fontSize: 14,
                        }}
                        onClick={() => {
                            localStorage.setItem(
                                constants.storage.payment,
                                JSON.stringify({
                                    plan: getCurrentPlan(currentPlanId),
                                })
                            );
                            setIsUpdatingPlan(false);
                            window.open(
                                getCurrentPlan(currentPlanId).pay_url,
                                "_self"
                            );
                        }}
                        // disabled={currentPlan === currentPlanId }
                    >
                        Save
                    </Button>
                </Box>
            ) : (
                <Button
                    variant="text"
                    sx={{
                        textTransform: "capitalize",
                        color: "#5BCFDE",
                        position: "absolute",
                        top: 20,
                        right: !isArabic && 20,
                        left: isArabic && 20,
                        fontSize: 14,
                    }}
                    onClick={() => setIsUpdatingPlan(true)}
                    // disabled={currentPlan === currentPlanId }
                >
                    Update
                </Button>
            )}
        </GridCard>
    );
};

export { MyPlanInfo };
