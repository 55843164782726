import React from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { constants } from "../../../../config/constants";
import { CurrencyIcon } from "../../../../common/components/Currency/CurrencyIcon";

const TableView = ({ items, startDate, endDate, withViewButton = true }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const navigate = useNavigate();

    const redirectToCustomerPage = (data) => {
        navigate(
            `${data.customerid}/${data.clv}/${data.n_of_transactions}/${startDate}/${endDate}`
        );
    };

    return (
        <Box>
            {!!items.length && (
                <TableContainer
                    sx={{
                        border: "1px solid #E5E6E9",
                        borderRadius: 5,
                        backgroundColor: "white",
                    }}
                >
                    <Table aria-label="simple table">
                        <TableHead
                            sx={{
                                backgroundColor: "#f9fafb",
                                "& .MuiTableCell-root": { color: "#7c818e" },
                            }}
                        >
                            <TableRow>
                                <TableCell
                                    align={isArabic ? "right" : "left"}
                                    colSpan={2}
                                >
                                    {t("common.name")}
                                </TableCell>
                                <TableCell align={isArabic ? "right" : "left"}>
                                    {t("common.gender")}
                                </TableCell>
                                <TableCell align={isArabic ? "right" : "left"}>
                                    {t("common.n_of_transactions")}
                                </TableCell>
                                <TableCell align={isArabic ? "right" : "left"}>
                                    {t("common.spent")}
                                </TableCell>
                                <TableCell align={isArabic ? "right" : "left"}>
                                    {t("common.ltv")}
                                </TableCell>
                                <TableCell align={isArabic ? "right" : "left"}>
                                    {t("common.last_engagement")}
                                </TableCell>
                                {withViewButton && (
                                    <TableCell
                                        align={isArabic ? "right" : "left"}
                                    >
                                        {t("common.action")}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((row, i) => (
                                <TableRow
                                    key={row.customer_id + i}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        colSpan={2}
                                        align={isArabic ? "right" : "left"}
                                    >
                                        {row.customer}
                                    </TableCell>
                                    <TableCell
                                        align={isArabic ? "right" : "left"}
                                        sx={{ color: "#6B7280" }}
                                    >
                                        {" "}
                                        {row.gender}
                                    </TableCell>
                                    <TableCell
                                        align={isArabic ? "right" : "left"}
                                        sx={{ color: "#6B7280" }}
                                    >
                                        {row.n_of_transactions}
                                    </TableCell>
                                    <TableCell
                                        align={isArabic ? "right" : "left"}
                                    >
                                        {row.spent} <CurrencyIcon />
                                    </TableCell>
                                    <TableCell
                                        align={isArabic ? "right" : "left"}
                                    >
                                        {row.clv} <CurrencyIcon />
                                    </TableCell>
                                    <TableCell
                                        align={isArabic ? "right" : "left"}
                                        sx={{ color: "#6B7280" }}
                                    >
                                        {row.last_engagement}
                                    </TableCell>
                                    {withViewButton && (
                                        <TableCell
                                            align={isArabic ? "right" : "left"}
                                            onClick={() =>
                                                redirectToCustomerPage(row)
                                            }
                                            sx={{
                                                cursor: "pointer",
                                                color: "#5BCFDE",
                                            }}
                                        >
                                            {t("common.view")}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export { TableView };
