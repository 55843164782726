import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Checkbox,
    Box,
    FormControlLabel,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { services } from "../../../../manager/services";
import { toast } from "react-toastify";

const MergeDialog = ({
    open,
    setOpen,
    ownerBranches,
    selectedBranches,
    setMergedBranches,
    newBranchName,
    setNewBranchName,
    setSelectedBranches,
    refetchMapped,
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const { mutate: createBranchMerge } = useMutation({
        mutationFn: services.createBranchMerge,
        onSuccess: (res) => {
            if (!res.data.error) {
                toast.success(t("common.done"));
                if (refetchMapped) refetchMapped();
            } else if (res.data.error) {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    const handleMerge = () => {
        if (!newBranchName) return;

        createBranchMerge({
            branch_ids: selectedBranches,
            source_name: newBranchName,
        });

        setOpen(false);
        setNewBranchName("");
        setSelectedBranches([]);
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle
                sx={{
                    textAlign: isArabic ? "right" : "left",
                    fontWeight: 600,
                }}
            >
                {t("common.map_branches")}
            </DialogTitle>
            <DialogContent>
                <Box display="flex" alignItems="center" flexWrap="wrap">
                    {ownerBranches.map((branch) => (
                        <FormControlLabel
                            key={branch.id}
                            control={
                                <Checkbox
                                    checked={selectedBranches.includes(
                                        branch.id
                                    )}
                                    onChange={() =>
                                        setSelectedBranches((prev) =>
                                            prev.includes(branch.id)
                                                ? prev.filter(
                                                      (id) => id !== branch.id
                                                  )
                                                : [...prev, branch.id]
                                        )
                                    }
                                    sx={{
                                        mr: 1 /* Adds spacing between checkbox and label */,
                                    }}
                                />
                            }
                            label={branch.name}
                            sx={{
                                fontSize: "14px",
                                color: "#333",
                            }}
                        />
                    ))}
                </Box>
                <TextField
                    fullWidth // ✅ Ensure full width is applied correctly
                    sx={{
                        mt: 2,
                        borderRadius: "6px",
                        border: "1px solid #ccc",
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "#ccc",
                            },
                            "&:hover fieldset": {
                                borderColor: "#22C55E",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "#22C55E",
                                boxShadow: "0 0 4px rgba(34, 197, 94, 0.3)",
                            },
                        },
                    }}
                    label={t("common.name")}
                    value={newBranchName}
                    onChange={(e) => setNewBranchName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setOpen(false)}
                    sx={{
                        textTransform: "none",
                        color: "#555",
                        "&:hover": {
                            color: "#222",
                            backgroundColor: "transparent",
                        },
                    }}
                >
                    {t("common.cancel")}
                </Button>
                <Button
                    disabled={selectedBranches.length < 2}
                    onClick={handleMerge}
                    variant="contained"
                    sx={{
                        textTransform: "none",
                        padding: "10px 16px" /* Makes button size consistent */,
                        fontSize: "14px",
                        backgroundColor: "#00C08B",
                        "&:hover": {
                            backgroundColor: "#00C08B",
                            color: "#FFFFFF",
                        },
                        // "&:disabled": {
                        //     backgroundColor: "#A7F3D0",
                        //     color: "#fff",
                        // },
                    }}
                >
                    {t("navigation.confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MergeDialog;
