import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Modal,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomTextField, OutlineButton } from "../../../common/components";
import { GridCard } from "../../../common/components";
import { getUserStorage } from "../../../common/utils";
import { useEffect, useState } from "react";
import { services } from "../../manager/services";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { constants } from "../../../config/constants";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { CurrencyIcon } from "../../../common/components/Currency/CurrencyIcon";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 10,
    borderRadius: 4,
};
const CreateGroupManager = () => {
    const newUserCost = 49;
    const { t } = useTranslation();

    const [isEdit, setIsEdit] = useState(false);
    const navigate = useNavigate();
    const user = getUserStorage();
    const allGroups =
        user?.group?.map((group) => ({
            id: group.merchant_id,
            value: group.merchant_id,
            label: group.name,
        })) || [];

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [selected, setSelected] = useState(
        JSON.parse(sessionStorage.getItem("toBeEditedManager"))?.merchant_ids ||
            []
    );

    const { handleSubmit, control } = useForm({
        defaultValues: {
            name: "",
            email: "",
            phone: "",
            ...JSON.parse(sessionStorage.getItem("toBeEditedManager")),
        },
    });

    const { mutate: createOwner, isLoading } = useMutation({
        mutationFn: JSON.parse(sessionStorage.getItem("toBeEditedManager"))
            ? services.updateManager
            : services.createOwner,
        onSuccess: (res) => {
            if (!res.data.error) {
                toast.success(res.data.response, {
                    hideProgressBar: true,
                });
                navigate(-1);
                sessionStorage.removeItem("toBeEditedManager");
            } else {
                if (res.data.code === 401) {
                    handleOpen();
                }
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    const onSubmit = (values) => {
        const preValues = values;
        createOwner({
            ...preValues,
            merchant_ids: selected,
        });
    };

    const onCancel = () => navigate(-1);

    const handlePaybranch = () => {
        const addUserLink =
            "https://zohosecurepay.com/checkout/19fgtf9-68nwqb1seivfy/Add-User";

        localStorage.setItem(
            constants.storage.payment_user,
            JSON.stringify({
                isAddUser: true,
            })
        );

        window.open(addUserLink, "_self");
    };

    useEffect(() => {
        if (JSON.parse(sessionStorage.getItem("toBeEditedManager")))
            setIsEdit(true);
        // sessionStorage.removeItem("toBeEditedManager");
    }, []);

    useEffect(() => {
        return () => sessionStorage.removeItem("toBeEditedManager");
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack padding={8} spacing={8} minWidth="100%">
                <Stack flexDirection="row" justifyContent="space-between">
                    <Typography variant="h5" fontSize={30} fontWeight="bold">
                        {t("settings.my_team")}
                    </Typography>
                </Stack>

                <Grid container flexWrap="nowrap">
                    <GridCard
                        fullWidth
                        boxStyles={{ minHeight: 420, height: "fit-content" }}
                    >
                        <Stack p={8} spacing={4}>
                            <Stack spacing={1}>
                                <Typography color="#0F172A" fontSize={16}>
                                    {t("settings.manager_info")}
                                </Typography>
                                <Typography color="#6B7280" fontSize={14}>
                                    {t("settings.create_manager_info")}{" "}
                                </Typography>
                            </Stack>

                            <Stack spacing={6}>
                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            {t("settings.manager_name")}
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder={t(
                                                        "settings.prompt_name"
                                                    )}
                                                    variant="outlined"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>

                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            {t("settings.manager_email")}
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder={t(
                                                        "settings.prompt_email"
                                                    )}
                                                    variant="outlined"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>

                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            {t("settings.manager_phone")}
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        <Controller
                                            name="phone"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    fullWidth
                                                    placeholder={t(
                                                        "settings.prompt_phone"
                                                    )}
                                                    variant="outlined"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>

                                <Box display="flex" alignItems="center">
                                    <Box width={250}>
                                        <Typography
                                            color="#4D5568"
                                            fontSize={14}
                                        >
                                            {t("settings.merchant")}
                                        </Typography>
                                    </Box>

                                    <Box width={400}>
                                        {allGroups.map((group) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        style={{
                                                            padding: "0",
                                                            paddingInlineEnd: 8,
                                                        }}
                                                        checked={selected.includes(
                                                            group.value
                                                        )}
                                                        onChange={(
                                                            e,
                                                            nextValue
                                                        ) => {
                                                            if (
                                                                selected.includes(
                                                                    group.value
                                                                )
                                                            )
                                                                setSelected(
                                                                    selected.filter(
                                                                        (i) =>
                                                                            i !==
                                                                            group.value
                                                                    )
                                                                );
                                                            else
                                                                setSelected([
                                                                    ...selected,
                                                                    group.value,
                                                                ]);
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <Typography variant="body2">
                                                        {group.label}
                                                    </Typography>
                                                }
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            </Stack>
                        </Stack>
                    </GridCard>
                </Grid>
                <Box
                    display="flex"
                    alignItems="center"
                    gap={4}
                    justifyContent="flex-end"
                >
                    <OutlineButton
                        onClick={onCancel}
                        buttonStyles={{ borderRadius: "6px", width: 80 }}
                        buttonProps={{ disabled: isLoading }}
                    >
                        {t("common.cancel")}
                    </OutlineButton>
                    <OutlineButton
                        buttonStyles={{
                            borderRadius: "6px",
                            width: isEdit ? 60 : 80,
                            color: "white",
                            backgroundColor: "#5BCFDE",
                            "&:hover": {
                                border: "1px solid #EEEFF4",
                                backgroundColor: "#5BCFDE",
                            },
                        }}
                        buttonProps={{
                            type: "submit",
                            disabled: isLoading || !selected.length,
                        }}
                    >
                        {isEdit ? t("common.save") : t("common.create")}
                    </OutlineButton>
                </Box>
            </Stack>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack spacing={5}>
                        <Box display="flex" alignItems="center" gap={2}>
                            <WarningAmberIcon sx={{ color: "#FFD700" }} />
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                            >
                                Users Limit Reached
                            </Typography>
                        </Box>

                        <Stack>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                            >
                                You have reached to the maximum number of users
                                on your subscribtion plan!
                            </Typography>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                            >
                                but you can add a new user for {newUserCost}{" "}
                                <CurrencyIcon />
                            </Typography>
                        </Stack>
                    </Stack>

                    <Box
                        display="flex"
                        mt={10}
                        justifyContent="flex-end"
                        gap={5}
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                textTransform: "capitalize",
                                borderRadius: "6px",
                            }}
                            onClick={handleClose}
                        >
                            {t("common.cancel")}
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                textTransform: "capitalize",
                                borderRadius: "6px",
                                width: "fit-content",
                                color: "white",
                                backgroundColor: "#5BCFDE",
                                border: "1px solid #EEEFF4",
                                "&:hover": {
                                    border: "1px solid #EEEFF4",
                                    backgroundColor: "#5BCFDE",
                                },
                            }}
                            onClick={handlePaybranch}
                        >
                            {t("common.proceed")}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </form>
    );
};

export { CreateGroupManager };
