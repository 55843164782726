import { useEffect, useState } from "react";
import {
    Grid,
    MenuItem,
    Select,
    Stack,
    Typography,
    Box,
    Divider,
    LinearProgress,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { CustomSelect, ProgressPie } from "../../../../common/components";
import { GridCardHeader } from "../../../../common/components";
import { services } from "../../services";
import { constants } from "../../../../config/constants";
import { numberWithCommas } from "../../../../common/utils/numberWithCommas";
import { CurrencyIcon } from "../../../../common/components/Currency/CurrencyIcon";

const SalesGoals = ({ period }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.only("xs"));

    const [salesGoalIteration, setSalesGoalIteration] = useState("yearly");
    const [salesGoal, setSalesGoal] = useState({
        accomplished: 0,
        target: 0,
        remaining: 0,
    });

    const { mutate: getSalesGoal, isSendingInvitation } = useMutation({
        mutationFn: services.getSalesGoal,
        onSuccess: (res) => {
            if (!res.data.error) {
                const response = res.data.response;

                setSalesGoal(response[0]);
            }
        },
        onError: (err) => console.log(err),
    });

    const handleSalesIterationChange = (value) => {
        setSalesGoalIteration(value);
        getSalesGoal({
            duration: value,
            from_date: period.startDate,
            till_date: period.endDate,
        });
    };

    useEffect(() => {
        if (period.startDate) {
            getSalesGoal({
                duration: salesGoalIteration,
                from_date: period.startDate,
                till_date: period.endDate,
            });
        }
    }, [period]);

    let accomplishedPerc =
        (+salesGoal?.accomplished / +salesGoal?.target).toFixed(2) * 100 || 0;
    let remainingPerc = accomplishedPerc ? 100 - accomplishedPerc : 0;

    accomplishedPerc = accomplishedPerc > 100 ? 100 : accomplishedPerc;
    remainingPerc = remainingPerc < 0 ? 0 : remainingPerc;

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <GridCardHeader title={t("overview.sales_goal")} />

                <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={salesGoalIteration}
                    onChange={(e) => handleSalesIterationChange(e.target.value)}
                    input={<CustomSelect />}
                >
                    <MenuItem value="today">{t("overview.today")}</MenuItem>
                    <MenuItem value="weekly">{t("overview.weekly")}</MenuItem>
                    <MenuItem value="monthly">{t("overview.monthly")}</MenuItem>
                    <MenuItem value="yearly">{t("overview.yearly")}</MenuItem>
                </Select>
            </Box>

            <Divider />

            <Stack gap={6} paddingY={6} paddingX={isMobileView ? 0 : 15}>
                <Box>
                    <Typography
                        fontSize={isMobileView ? 24 : 36}
                        color="#202020"
                        textAlign={isMobileView && "center"}
                    >
                        {numberWithCommas(salesGoal?.target) || 0}{" "}
                        <CurrencyIcon />
                    </Typography>
                </Box>

                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Box
                            display="flex"
                            justifyContent={isMobileView && "center"}
                        >
                            <ProgressPie
                                completedPerc={accomplishedPerc || 0}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} paddingTop={4}>
                        <Stack
                            spacing={5}
                            alignItems={isMobileView && "center"}
                        >
                            <Stack spacing={1}>
                                <Typography fontSize="14px" color="#363C47">
                                    {t("overview.accomplished")}
                                </Typography>

                                <Stack
                                    direction="row"
                                    spacing={2}
                                    width={132}
                                    alignItems="center"
                                >
                                    <Box width="100%">
                                        <LinearProgress
                                            sx={{
                                                height: "6px",
                                                borderRadius: 3,
                                                maxWidth: 95,
                                            }}
                                            variant="determinate"
                                            value={accomplishedPerc}
                                        />
                                    </Box>

                                    <Typography color="#363C47" fontSize="14px">
                                        {isArabic
                                            ? `%${accomplishedPerc}`
                                            : `${accomplishedPerc}%`}
                                    </Typography>
                                </Stack>
                            </Stack>

                            <Stack spacing={1}>
                                <Typography fontSize="14px" color="#363C47">
                                    {t("overview.remaining")}
                                </Typography>

                                <Stack
                                    direction="row"
                                    spacing={2}
                                    width={132}
                                    alignItems="center"
                                >
                                    <Box width="100%">
                                        <LinearProgress
                                            variant="determinate"
                                            value={remainingPerc}
                                            sx={{
                                                height: "6px",
                                                borderRadius: 3,
                                                maxWidth: 95,
                                            }}
                                        />
                                    </Box>

                                    <Typography color="#363C47" fontSize="14px">
                                        {isArabic
                                            ? `%${remainingPerc}`
                                            : `${remainingPerc}%`}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Stack>
    );
};

export { SalesGoals };
