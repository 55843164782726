import {
    Box,
    Button,
    Divider,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    tableCellClasses,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import styled from "styled-components";
import { constants } from "../../../../config/constants";
import { numberWithCommas } from "../../../../common/utils/numberWithCommas";
import { CurrencyIcon } from "../../../../common/components/Currency/CurrencyIcon";

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#FAFCFE",
        color: "#999999",
    },
    [`&.${tableCellClasses.root}`]: {
        lineHeight: 1,
        paddingLeft: 24,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {},
    [`&.${tableCellClasses.root}`]: {
        lineHeight: 1,
        paddingLeft: 24,
    },
}));

const TopCustomersTable = ({ data, withViewAll = true }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <Typography fontWeight="bold" fontSize={20} color="#202020">
                    {t("customer.top_customers")}
                </Typography>

                {withViewAll && (
                    <Button
                        variant="outlined"
                        sx={{
                            height: 26,
                            minWdth: 83,
                            borderRadius: 2,
                            color: "#434854",
                            borderColor: "#edecef",
                            textTransform: "capitalize",
                        }}
                    >
                        {t("common.view_all")}
                    </Button>
                )}
            </Box>

            <Divider />

            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableHeadCell
                                colSpan={6}
                                align={isArabic ? "right" : "left"}
                            >
                                {t("customer.customer")}
                            </StyledTableHeadCell>
                            <StyledTableHeadCell
                                align={isArabic ? "right" : "left"}
                                colSpan={4}
                            >
                                {t("overview.ltv")}
                            </StyledTableHeadCell>
                            <StyledTableHeadCell
                                align={isArabic ? "right" : "left"}
                            >
                                {t("common.date_engagement")}
                            </StyledTableHeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!data?.length &&
                            Array.from(Array(4).keys()).map((i) => (
                                <TableRow
                                    key={i}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <StyledTableCell
                                        component="th"
                                        scope="row"
                                        colSpan={6}
                                    >
                                        -
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align={isArabic ? "right" : "left"}
                                        colSpan={4}
                                    >
                                        -
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align={isArabic ? "right" : "left"}
                                    >
                                        -
                                    </StyledTableCell>
                                </TableRow>
                            ))}

                        {data?.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                <StyledTableCell
                                    component="th"
                                    scope="row"
                                    colSpan={6}
                                    align={isArabic ? "right" : "left"}
                                >
                                    {row.name}
                                </StyledTableCell>
                                <StyledTableCell
                                    align={isArabic ? "right" : "left"}
                                    colSpan={4}
                                >
                                    {numberWithCommas(row.clv)} <CurrencyIcon />
                                </StyledTableCell>
                                <StyledTableCell
                                    align={isArabic ? "right" : "left"}
                                    sx={{ color: "#71767C" }}
                                >
                                    {row.last_engagement}
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
};

export { TopCustomersTable };
