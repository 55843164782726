import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getUserStorage } from "../../../../common/utils";
import MergeDialog from "./components/MergeDialog";
import EditDialog from "./components/EditDialog";
import DeleteDialog from "./components/DeleteDialog";
import BranchesList from "./components/BranchesList";
import BranchSelection from "./components/BranchSelection";
import { useQuery } from "@tanstack/react-query";
import { services } from "../../../manager/services";

const BranchesMap = () => {
    const { t } = useTranslation();

    const user = getUserStorage();
    const [ownerBranches] = useState(
        user?.merchant?.branches?.map((branch) => ({
            id: branch.branch_id,
            name: branch.name,
        })) || []
    );

    const {
        data,
        isFetchingMergedBranches,
        refetch: refetchMapped,
    } = useQuery({
        queryKey: ["allMapped"],
        queryFn: services.getBranchMerge,
    });

    const [selectedBranches, setSelectedBranches] = useState([]);
    const [mergedBranches, setMergedBranches] = useState([]);

    const [openMergeDialog, setOpenMergeDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [newBranchName, setNewBranchName] = useState("");
    const [editBranchId, setEditBranchId] = useState(null);
    const [editSelectedBranches, setEditSelectedBranches] = useState([]);
    const [branchToDelete, setBranchToDelete] = useState(null);

    useEffect(() => {
        if (data?.data?.response) {
            setMergedBranches(data?.data?.response);
        }
    }, [data?.data?.response]);

    return (
        <Stack
            spacing={4}
            padding={4}
            backgroundColor="white"
            borderRadius="12px"
        >
            {/* Branch Selection */}

            {/* Merged Branches List */}
            <Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h6" fontWeight={600}>
                        {t("navigation.branchesMap")}
                    </Typography>

                    <BranchSelection
                        ownerBranches={ownerBranches}
                        selectedBranches={selectedBranches}
                        setSelectedBranches={setSelectedBranches}
                        setOpenMergeDialog={setOpenMergeDialog}
                    />
                </Box>
                <BranchesList
                    mergedBranches={mergedBranches}
                    setOpenEditDialog={setOpenEditDialog}
                    setEditBranchId={setEditBranchId}
                    setNewBranchName={setNewBranchName}
                    setEditSelectedBranches={setEditSelectedBranches}
                    ownerBranches={ownerBranches}
                    setOpenDeleteDialog={setOpenDeleteDialog}
                    setBranchToDelete={setBranchToDelete}
                />
            </Box>

            {/* Dialogs */}
            <MergeDialog
                open={openMergeDialog}
                setOpen={setOpenMergeDialog}
                ownerBranches={ownerBranches}
                selectedBranches={selectedBranches}
                setMergedBranches={setMergedBranches}
                newBranchName={newBranchName}
                setNewBranchName={setNewBranchName}
                setSelectedBranches={setSelectedBranches}
                refetchMapped={refetchMapped}
            />

            <EditDialog
                open={openEditDialog}
                setOpen={setOpenEditDialog}
                ownerBranches={ownerBranches}
                mergedBranches={mergedBranches}
                setMergedBranches={setMergedBranches}
                editBranchId={editBranchId}
                newBranchName={newBranchName}
                setNewBranchName={setNewBranchName}
                editSelectedBranches={editSelectedBranches}
                setEditSelectedBranches={setEditSelectedBranches}
                refetchMapped={refetchMapped}
            />

            <DeleteDialog
                open={openDeleteDialog}
                setOpen={setOpenDeleteDialog}
                branchToDelete={branchToDelete}
                setMergedBranches={setMergedBranches}
                refetchMapped={refetchMapped}
            />
        </Stack>
    );
};

export { BranchesMap };
