import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Box,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { services } from "../../../../manager/services";
import { toast } from "react-toastify";

const EditDialog = ({
    open,
    setOpen,
    ownerBranches,
    mergedBranches,
    setMergedBranches,
    editBranchId,
    newBranchName,
    setNewBranchName,
    editSelectedBranches,
    setEditSelectedBranches,
    refetchMapped,
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const { mutate: updateBranchMerge } = useMutation({
        mutationFn: services.updateBranchMerge,
        onSuccess: (res) => {
            if (!res.data.error) {
                toast.success(t("common.done"));
                if (refetchMapped) refetchMapped();
            } else if (res.data.error) {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    // Handle branch selection inside the edit modal
    const handleEditBranchSelection = (id) => {
        setEditSelectedBranches((prev) =>
            prev.includes(id)
                ? prev.filter((branchId) => branchId !== id)
                : [...prev, id]
        );
    };

    // Save the edited branch name and selected branches
    const handleSaveEdit = () => {
        if (!newBranchName.trim()) return;

        updateBranchMerge({
            name: newBranchName,
            branch_ids: editSelectedBranches,
            source_branch_id: editBranchId,
        });

        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle sx={{ textAlign: isArabic ? "right" : "left" }}>
                {t("common.edit")}
            </DialogTitle>
            <DialogContent sx={{ padding: "24px" }}>
                {/* Branch Name Input */}
                <TextField
                    fullWidth
                    label={t("common.name")}
                    value={newBranchName}
                    onChange={(e) => setNewBranchName(e.target.value)}
                    margin="normal"
                />

                {/* Branch Selection (Checkboxes) */}
                <Box display="flex" flexWrap="wrap" gap={2} mt={2}>
                    {ownerBranches.map((branch) => (
                        <FormControlLabel
                            key={branch.id}
                            control={
                                <Checkbox
                                    checked={editSelectedBranches.includes(
                                        branch.id
                                    )}
                                    onChange={() =>
                                        handleEditBranchSelection(branch.id)
                                    }
                                />
                            }
                            label={branch.name}
                        />
                    ))}
                </Box>
            </DialogContent>

            {/* Actions */}
            <DialogActions>
                <Button
                    onClick={() => setOpen(false)}
                    sx={{ textTransform: "none" }}
                >
                    {t("common.cancel")}
                </Button>
                <Button
                    disabled={editSelectedBranches.length < 2}
                    onClick={handleSaveEdit}
                    variant="contained"
                    sx={{
                        textTransform: "none",
                        backgroundColor: "#00C08B",
                        "&:hover": {
                            backgroundColor: "#00C08B",
                            color: "#FFFFFF",
                        },
                    }}
                >
                    {t("common.edit")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditDialog;
