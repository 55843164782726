import React from "react";
import { useTranslation } from "react-i18next";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { CurrencyIcon } from "../../Currency/CurrencyIcon";
// import { constants } from "../../../../config/constants";

// const formatYAxisTick = (value) => {
//     return `${value} `; // Format the tick label with the desired unit
// };
const CustomTick = ({ x, y, payload, unit = true }) => {
    const { t } = useTranslation();
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
                {payload.value} {unit ? <CurrencyIcon /> : ""}
            </text>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
                {payload?.date}
            </text>
        </g>
    );
};

const SimpleLineChart = ({
    data,
    container = { width: "100%", height: 260 },
    withGrid = true,
    xAxisConfigs,
    yAxisConfigs,
    xKey = "pv",
    yKey = "uv",
    noComparison,
    unit,
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    return (
        <ResponsiveContainer width={container.width} height={container.height}>
            <LineChart
                data={data}
                margin={{
                    top: 30,
                    right: isArabic ? 50 : 25,
                    left: isArabic ? 25 : 50,
                    bottom: 0,
                }}
            >
                {withGrid && (
                    <CartesianGrid
                        strokeDasharray="0"
                        vertical={false}
                        horizontal={false}
                    />
                )}
                <XAxis
                    dataKey="name"
                    axisLine={{ stroke: "#EEEFF4", strokeWidth: 2 }}
                    tickLine={false}
                    tick={false}
                    {...xAxisConfigs}
                />
                <YAxis
                    // tickFormatter={formatYAxisTick}
                    // tick={{ fontWeight: "bold" }}
                    axisLine={{ stroke: "#EEEFF4", strokeWidth: 2 }}
                    dataKey={xKey}
                    tickLine={false}
                    unit={unit && t("common.currency")}
                    tick={(props) => <CustomTick {...props} unit={unit} />}
                    {...yAxisConfigs}
                />
                <Tooltip />

                <Line
                    type="linear"
                    dataKey={xKey}
                    stroke="#5BCFDE"
                    strokeWidth={2}
                    dot={false}
                />
                {!noComparison && (
                    <Line
                        type="linear"
                        dataKey={yKey}
                        stroke="#DDFBA9"
                        strokeWidth={2}
                        dot={false}
                    />
                )}
            </LineChart>
        </ResponsiveContainer>
    );
};

export { SimpleLineChart };
