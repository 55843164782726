import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, ListItem, ListItemText } from "@mui/material";
import { sidebarItemStyles } from "./SidebarStyles";
import { useTranslation } from "react-i18next";

const SidebarItem = ({
    label,
    path,
    isActive,
    icon,
    iconPath,
    isDisabled,
    isBeta,
    isSoon,
}) => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const isArabic = i18n.language === "ar";

    return (
        <ListItem
            disablePadding
            onClick={() => !isDisabled && navigate(path)}
            sx={{
                ...sidebarItemStyles.base,
                ...(isActive && sidebarItemStyles.active),
                "&:hover": sidebarItemStyles.hover,
            }}
            disabled={isDisabled}
        >
            {iconPath && (
                <img
                    width="18px"
                    src={iconPath}
                    alt="sideNavBarIcon"
                    style={{
                        marginRight: "8px",
                        marginLeft: "8px",
                    }}
                />
            )}
            {icon && (
                <span
                    style={{
                        marginRight: "8px",
                        marginLeft: "8px",
                        color: isActive ? "#00D1B2" : "#B0BEC5",
                    }}
                >
                    {icon}
                </span>
            )}
            <ListItemText
                primary={label}
                primaryTypographyProps={{
                    variant: "body1",
                    style: { color: isActive ? "#FFFFFF" : "#B0BEC5" },
                    textAlign: isArabic ? "right" : "left",
                }}
            />

            {isSoon && (
                <Box
                    // position="absolute"
                    backgroundColor="#3498db"
                    borderRadius="3px"
                    padding="0px 4px"
                    color="white"
                    fontSize="10px"
                    // top={0}
                    // left={isArabic ? 16 : -20}
                >
                    Soon
                </Box>
            )}
            {isBeta && (
                <Box
                    // position="absolute"
                    backgroundColor="#1abc9c"
                    borderRadius="3px"
                    padding="0px 4px"
                    color="white"
                    fontSize="10px"
                    // top={0}
                    // left={isArabic ? 16 : -20}
                >
                    Beta
                </Box>
            )}
        </ListItem>
    );
};

export default SidebarItem;
