import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const POSCard = ({
    isConnected,
    logo,
    name,
    hlink,
    description,
    handleResync,
    shouldDisableResync,
    resyncRequested,
}) => {
    const { t } = useTranslation();

    return (
        <Stack
            position="relative"
            justifyContent="space-between"
            backgroundColor="white"
            boxShadow="0 1px 3px hsla(220,6%,43%,0.2)"
            padding={8}
            borderRadius={2}
            spacing={6}
            maxWidth="550px"
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                {isConnected ? (
                    <Typography variant="h6">{name}</Typography>
                ) : (
                    <Typography variant="h6">Connect To {name}</Typography>
                )}

                <Box width="40px">
                    <img
                        alt="Deliverect logo"
                        src={logo}
                        width="100%"
                        height="100%"
                    />
                </Box>
            </Box>

            <Stack spacing={2}>
                <Typography>{description}</Typography>
            </Stack>

            <Stack spacing={4}>
                <Link to={"https://" + hlink} target="_blank">
                    <Typography sx={{ color: "#440099" }}>{hlink}</Typography>
                </Link>
                <Divider />
                <Box display="flex" alignItems="center" gap={6}>
                    <Box display="flex" alignItems="center" gap={2}>
                        <Box
                            sx={{
                                width: 8,
                                height: 8,
                                borderRadius: "50%",
                                backgroundColor: isConnected
                                    ? "#4BB543"
                                    : "#FF0000",
                            }}
                        />
                        <Typography variant="body" align="center">
                            {isConnected
                                ? t("top_nav.connected")
                                : t("top_nav.not_connected")}
                        </Typography>
                    </Box>

                    {isConnected ? (
                        <Button
                            startIcon={<CachedIcon />}
                            onClick={handleResync}
                            disabled={shouldDisableResync || resyncRequested}
                            variant="outlined"
                            sx={{ textTransform: "capitalize" }}
                        >
                            {t("top_nav.resync")}
                        </Button>
                    ) : null}
                </Box>
            </Stack>
        </Stack>
    );
};

export default POSCard;
