import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { services } from "../services";
import { toast } from "react-toastify";
import { constants } from "../../../config/constants";
import { differenceInMinutes } from "date-fns";
import { useTranslation } from "react-i18next";
import POSCard from "./POSCard";
import { getUserStorage } from "../../../common/utils";

const AVAILABLE_INTEGRATIONS = {
    FOODICS: {
        name: "Foodics",
        logo: "/foodics_logo.jpeg",
        description:
            "Point of sale & restaurant management solution. Restaurant management made easy. Elevate your restaurant's guest experience and business operations with Foodics solutions.",
        hlink: "https://www.foodics.com",
    },
    DELIVERECT: {
        name: "Deliverect",
        logo: "/Deliverect_logo.png",
        description:
            "Online orders are aggregated, analyzed, and visualized into a single source of truth on your Ballurh's dashboard.",
        hlink: "https://www.deliverect.com",
    },
    FLAVOURS: {
        name: "Flavours",
        logo: "/Flavours_logo.png",
        description:
            "A complete cashier system in terms of the features that a business owner needs to manage his business.",
        hlink: "https://www.flavours.sa",
    },
    URBANPIPER: {
        name: "UrbanPiper",
        logo: "/UrabPiper_logo.jpg",
        description:
            "Online orders are aggregated, analyzed, and visualized into a single source of truth on your Ballurh's dashboard.",
        hlink: "https://www.urbanpiper.com",
    },
};

const Integrations = () => {
    const { t } = useTranslation();
    const user = getUserStorage();
    const integrations = user?.merchant?.integrations || [];
    const pos = user?.merchant?.pos;

    const [resyncRequested, setResyncRequestd] = useState(false);

    const notify = () =>
        toast.info(
            "Your resync request is being processed, this might take several minutes!",
            {
                hideProgressBar: true,
            }
        );

    const { mutate: resyncData } = useMutation({
        mutationFn: services.resyncData,
        onSuccess: (res) => {
            if (res.data.error) {
                setResyncRequestd(false);
                localStorage.removeItem(constants.storage.lastResyncDate);
            }
        },
        onError: (err) => console.log(err),
    });

    const handleResync = () => {
        setResyncRequestd(true);
        localStorage.setItem(
            constants.storage.lastResyncDate,
            JSON.stringify(new Date())
        );
        notify();
        resyncData();
    };

    const lastResyncDate = new Date(
        JSON.parse(localStorage.getItem(constants.storage.lastResyncDate))
    );

    const shouldDisableResync =
        differenceInMinutes(new Date(), lastResyncDate) < 15;

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h5">
                    {t("top_nav.integrations")}
                </Typography>
            </Box>
            <Grid container spacing={0} alignItems="stretch" gap={4}>
                {Object.keys(AVAILABLE_INTEGRATIONS).map((key) => {
                    const integration = AVAILABLE_INTEGRATIONS[key];
                    return (
                        <POSCard
                            key={integration.name}
                            name={integration.name}
                            logo={integration.logo}
                            description={integration.description}
                            hlink={integration.hlink}
                            pos={pos}
                            handleResync={handleResync}
                            shouldDisableResync={shouldDisableResync}
                            resyncRequested={resyncRequested}
                            isConnected={integrations.includes(key)}
                        />
                    );
                })}
            </Grid>
        </Stack>
    );
};

export { Integrations };
