import {
    List,
    ListItem,
    ListItemText,
    IconButton,
    Box,
    Typography,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const BranchesList = ({
    mergedBranches,
    setOpenEditDialog,
    setEditBranchId,
    setNewBranchName,
    setEditSelectedBranches,
    ownerBranches,
    setOpenDeleteDialog,
    setBranchToDelete,
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    if (!mergedBranches?.length)
        return (
            <Box sx={{ minHeight: "100px" }}>
                {" "}
                <Typography
                    variant="body1"
                    color="textSecondary"
                    textAlign="center"
                    sx={{ padding: "16px" }}
                >
                    {t("common.no_mapped")}
                </Typography>
            </Box>
        );

    return (
        <List sx={{ minHeight: "200px" }}>
            {mergedBranches?.map((branch) => {
                const mergedFrom = branch.branches.map((b) => b.branch_name);

                return (
                    <ListItem key={branch.id} divider>
                        <ListItemText
                            primary={branch.name}
                            secondary={`${mergedFrom.join(", ")}`}
                            sx={{ textAlign: isArabic ? "right" : "left" }}
                        />
                        <IconButton
                            onClick={() => {
                                setEditBranchId(branch.id);
                                setNewBranchName(branch.name);
                                setEditSelectedBranches(
                                    ownerBranches
                                        .filter((b) =>
                                            mergedFrom.includes(b.name)
                                        )
                                        .map((b) => b.id)
                                );
                                setOpenEditDialog(true);
                            }}
                            sx={{ color: "primary.main" }}
                        >
                            <Edit />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setBranchToDelete(branch);
                                setOpenDeleteDialog(true);
                            }}
                            sx={{ color: "error.main" }}
                        >
                            <Delete />
                        </IconButton>
                    </ListItem>
                );
            })}
        </List>
    );
};

export default BranchesList;
