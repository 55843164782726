import { useEffect, useMemo, useState } from "react";
import {
    Box,
    Grid,
    MenuItem,
    Select,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import {
    ButtonSelect,
    CustomSelectOutline,
    GridCard,
    RangePicker,
} from "../../../../../common/components";
import { InfoCard } from "../../components/InfoCard";
import { useMutation, useQuery } from "@tanstack/react-query";
import { format, isToday, subMonths, subYears } from "date-fns";
import { useTranslation } from "react-i18next";

import { numberWithCommas } from "../../../../../common/utils/numberWithCommas";
import { getUserStorage } from "../../../../../common/utils";
import { toast } from "react-toastify";
// import { ExportButton } from "../../../../../common/components/Export/Export";
import { MultiMeetricChartByDate } from "../charts/MultiMeetricChartByDate";
import { MultiMeetricChart } from "../charts/MultiMeetricChart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { differenceInDays, subDays } from "date-fns";
import { AuthServices } from "../../../../../Auth/services";
import { services } from "../services";
import SalesTabs from "../Tabs/SalesTabs";
import ChartSkeleton from "../charts/ChartSkeletion";
import { CurrencyIcon } from "../../../../../common/components/Currency/CurrencyIcon";

function getPreviousPeriod(startDate, endDate) {
    const daysDifference = differenceInDays(endDate, startDate);

    // General case for custom period
    const previousStartDate = subDays(startDate, daysDifference);
    const previousEndDate = subDays(endDate, daysDifference + 1);
    return { previousStartDate, previousEndDate };
}

function mergeArrays(arr1, arr2) {
    if (!arr2?.length) return arr1;

    const mergedArr = arr1.map((item, i) => ({
        ...item,
        prev: arr2[i],
    }));

    return mergedArr;
}

function calculatePercentageDifference(currentValue, prevValue) {
    if (currentValue === 0 && prevValue === 0) return 0;
    if (prevValue === 0) {
        return 100; // Prevent division by zero
    }
    const difference = currentValue - prevValue;
    const percentageChange = (difference / prevValue) * 100;

    return percentageChange.toFixed(2) + "%"; // Returns the result with two decimal places
}

const Sales = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const { data: firstDateData } = useQuery({
        queryKey: ["firstSalesDate"],
        queryFn: AuthServices.getMerchantFirstDate,
        refetchOnWindowFocus: false,
    });

    const [period, setPeriod] = useState({
        startDate: format(new Date(), "yyyy-MM-dd"),
        endDate: format(new Date(), "yyyy-MM-dd"),
    });

    const user = getUserStorage();
    const isOwner = user.role === 1;
    const userBranches =
        isOwner && !user.manager_view ? user?.merchant?.branches : user?.branch;
    const allBranches = userBranches.map((branch) => ({
        id: branch.branch_id,
        label: branch.name,
    }));
    const isGroupOwner = user.role === 9;

    const userGroups = user?.group;
    const allGroups =
        userGroups?.map((group) => ({
            id: group.merchant_id,
            label: group.name,
        })) || [];
    const allGroupsOption = { id: "all", label: t("common.all_groups") };
    const [selectedGroup, setSelectedGroup] = useState(allGroupsOption.id);

    const allChannelsOption = { id: "all", label: t("common.all_channels") };
    // const allBrandsOption = { id: "all", label: "All Brands" };
    const isManagerView = user.manager_view;
    const allBranchesOption = { id: "all", label: t("common.all_branches") };
    const [selectedBranch] = useState(
        isManagerView ? allBranches[0].id : allBranchesOption.id
    );
    const [selectedChannel] = useState(allChannelsOption.id);

    const [salesData, setSalesData] = useState(null);
    const [multiMeetricData, setMultiMeetricData] = useState(null);
    const [multiMeetricDataByDate, setMultiMeetricDataByDate] = useState(null);
    const [prevTotals, setPrevTotals] = useState(null);
    const [deliveryPerc, setDeliverPerc] = useState(null);
    const [deliveryTotal, setDeliveryTotal] = useState(null);

    const isTodayFilterApplied = useMemo(
        () =>
            isToday(new Date(period.startDate)) &&
            isToday(new Date(period.endDate)),
        [period]
    );

    const [compareWithPrev, setCompareWithPrev] =
        useState(isTodayFilterApplied);

    const [salesTab, setSalesTab] = useState(0);

    const { mutate: getSalesAggregatorsCards, isLoading } = useMutation({
        mutationFn: services.getSalesAggregatorsCards,
        onSuccess: (res) => {
            if (res.data.response && !res.data.error) {
                const response = res.data.response;

                setSalesData(res.data.response?.totals[0]);

                const orderTypesTotal = getTotalCount(response.order_types);

                const deliveryOrderType = response.order_types?.find(
                    (o) => o.order_type === "DELIVERY"
                );

                const prevDeliveryOrderType = response.prev_order_types?.find(
                    (o) => o.order_type === "DELIVERY"
                );

                if (deliveryOrderType) {
                    setDeliverPerc(
                        +(deliveryOrderType.total_sales / orderTypesTotal) * 100
                    );
                    setDeliveryTotal({
                        current: deliveryOrderType.total_sales,
                        prev: prevDeliveryOrderType?.total_sales,
                    });
                }

                setPrevTotals(response.prev_totals[0]);
            }

            if (res.data.error) {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    const {
        mutate: getSalesAggregatorsBranch,
        isLoading: isLoadingBranchChart,
    } = useMutation({
        mutationFn: services.getSalesAggregatorsBranch,
        onSuccess: (res) => {
            if (res.data.response && !res.data.error) {
                const response = res.data.response;

                const multiMeetricDataResponse = {
                    avg_basket_size: mergeArrays(
                        response.avg_basket_totals,
                        response.prev_avg_basket_totals
                    ),
                    total_sales: mergeArrays(
                        response.sales_agg_totals,
                        response.prev_sales_agg_totals
                    ),
                    total_orders: mergeArrays(
                        response.orders_totals,
                        response.prev_orders_totals
                    ),
                };

                setMultiMeetricData(multiMeetricDataResponse);
            }

            if (res.data.error) {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    const { mutate: getSalesAggregatorsDay, isLoading: isLoadingDayChart } =
        useMutation({
            mutationFn: services.getSalesAggregatorsDay,
            onSuccess: (res) => {
                if (res.data.response && !res.data.error) {
                    const response = res.data.response;

                    const multiMeetricDataResponseByDate = {
                        avg_basket_size: mergeArrays(
                            response.avg_basket_totals_interval,
                            response.prev_avg_basket_totals_interval
                        ),
                        total_sales: mergeArrays(
                            response.sales_agg_totals_interval,
                            response.prev_sales_agg_totals_interval
                        ),
                        total_orders: mergeArrays(
                            response.orders_totals_interval,
                            response.prev_orders_totals_interval
                        ),
                    };

                    setMultiMeetricDataByDate(multiMeetricDataResponseByDate);
                }

                if (res.data.error) {
                    toast.error(res.data.message, {
                        hideProgressBar: true,
                    });
                }
            },
            onError: (err) => console.log(err),
        });

    function getTotalCount(arr, key = "total_sales") {
        let count = 0;
        arr.forEach((i) => (count = count + +i[key]));

        return count;
    }

    const getPageData = (requestOptions) => {
        getSalesAggregatorsCards(requestOptions);

        if (salesTab === 0) getSalesAggregatorsBranch(requestOptions);
        else getSalesAggregatorsDay(requestOptions);
    };

    const handleSalesByTabChange = (newtab) => {
        setSalesTab(newtab);

        const prevDate = getPreviousPeriod(
            new Date(period.startDate),
            new Date(period.endDate)
        );

        const reqOptions = {
            from_date: period.startDate,
            till_date: period.endDate,
            ...(compareWithPrev
                ? {
                      prev_from_date: format(
                          new Date(prevDate.previousStartDate),
                          "yyyy-MM-dd"
                      ),
                      prev_till_date: format(
                          new Date(prevDate.previousEndDate),
                          "yyyy-MM-dd"
                      ),
                  }
                : {}),
            branch_id: selectedBranch,
            channel_id: selectedChannel,
        };
        if (newtab === 0) getSalesAggregatorsBranch(reqOptions);
        else getSalesAggregatorsDay(reqOptions);
    };

    useEffect(() => {
        if (period.startDate) {
            const prevDate = getPreviousPeriod(
                new Date(period.startDate),
                new Date(period.endDate)
            );

            const reqOptions = {
                from_date: period.startDate,
                till_date: period.endDate,
                ...(compareWithPrev
                    ? {
                          prev_from_date: format(
                              new Date(prevDate.previousStartDate),
                              "yyyy-MM-dd"
                          ),
                          prev_till_date: format(
                              new Date(prevDate.previousEndDate),
                              "yyyy-MM-dd"
                          ),
                      }
                    : {}),
                channel_id: "all",
                branch_id: "all",
            };

            getPageData(reqOptions);
        }
    }, [compareWithPrev, period.startDate, period.endDate]);

    const handleDatesChange = ({ startDate, endDate }) => {
        if (startDate !== "1970-1-1") {
            setPeriod({ startDate, endDate });
        }
    };

    const handleTodayDatesChange = (value) => {
        let prevStartDate = period.startDate;
        let prevEndDate = period.endDate;

        if (value === "yesterday") {
            prevStartDate = subDays(new Date(), 1);
            prevEndDate = subDays(new Date(), 1);
        }
        if (value === "same_day_prev_week") {
            prevStartDate = subDays(new Date(), 7);
            prevEndDate = subDays(new Date(), 7);
        }
        if (value === "same_day_prev_month") {
            prevStartDate = subMonths(new Date(), 1);
            prevEndDate = subMonths(new Date(), 1);
        }
        if (value === "same_day_prev_3_month") {
            prevStartDate = subMonths(new Date(), 3);
            prevEndDate = subMonths(new Date(), 3);
        }
        if (value === "same_day_prev_year") {
            prevStartDate = subYears(new Date(), 1);
            prevEndDate = subYears(new Date(), 1);
        }

        if (period.startDate !== "1970-1-1") {
            const reqOptions = {
                from_date: period.startDate,
                till_date: period.endDate,

                prev_from_date: format(new Date(prevStartDate), "yyyy-MM-dd"),
                prev_till_date: format(new Date(prevEndDate), "yyyy-MM-dd"),
                branch_id: selectedBranch,
                channel_id: selectedChannel,
            };
            getPageData(reqOptions);
        }
    };

    const handleGroupChange = (e) => {
        const newSelectedGroupValue = e.target.value;
        setSelectedGroup(newSelectedGroupValue);

        const reqOptions = {
            from_date: period.startDate,
            till_date: period.endDate,
            ...(compareWithPrev
                ? {
                      prev_from_date: period.startDate,
                      prev_till_date: period.endDate,
                  }
                : {}),
            merchant_filter: newSelectedGroupValue,
        };

        getPageData(reqOptions);
    };

    useEffect(() => {
        window.pendo.pageLoad({
            name: "Sales Aggregators Page",
        });
    }, []);

    const isAllTimeFilterApplied = useMemo(() => {
        if (!firstDateData?.data?.response?.first_date) return false;
        if (
            format(new Date(period.startDate), "yyyy-MM-dd") ===
                format(
                    new Date(firstDateData?.data?.response?.first_date),
                    "yyyy-MM-dd"
                ) &&
            isToday(new Date(period.endDate))
        ) {
            return true;
        }
    }, [firstDateData, period]);

    useEffect(() => {
        if (compareWithPrev && isAllTimeFilterApplied)
            setCompareWithPrev(false);
        if (!compareWithPrev && isTodayFilterApplied) setCompareWithPrev(true);
    }, [isTodayFilterApplied, compareWithPrev, isAllTimeFilterApplied]);

    return (
        <>
            <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" gap={3} alignItems="center">
                    <RangePicker
                        onChange={(values) => handleDatesChange(values)}
                        isLoading={
                            isLoading ||
                            isLoadingBranchChart ||
                            isLoadingDayChart
                        }
                    />

                    {isTodayFilterApplied ? (
                        <>
                            <Typography variant="body2">
                                {t("common.compared_to")}
                            </Typography>

                            <Select
                                defaultValue="yesterday"
                                onChange={(e) =>
                                    handleTodayDatesChange(e.target.value)
                                }
                                input={<CustomSelectOutline />}
                                IconComponent={ExpandMoreIcon}
                                disabled={
                                    isLoading ||
                                    isLoadingBranchChart ||
                                    isLoadingDayChart
                                }
                            >
                                <MenuItem value="yesterday">
                                    {" "}
                                    {t("likeForLike.yesterday")}
                                </MenuItem>
                                <MenuItem value="same_day_prev_week">
                                    {t("likeForLike.same_day_last_week")}
                                </MenuItem>
                                <MenuItem value="same_day_prev_month">
                                    {t("likeForLike.same_day_prev_month")}
                                </MenuItem>
                                <MenuItem value="same_day_prev_3_month">
                                    {t("likeForLike.same_day_last_qur")}
                                </MenuItem>
                                <MenuItem value="same_day_prev_year">
                                    {t("likeForLike.same_day_last_year")}
                                </MenuItem>
                            </Select>
                        </>
                    ) : (
                        !isAllTimeFilterApplied && (
                            <Box display="flex" alignItems="center">
                                <Typography>
                                    {t("sales.compare_to_prev")}
                                </Typography>
                                <Switch
                                    disabled={
                                        isLoading ||
                                        isLoadingBranchChart ||
                                        isLoadingDayChart
                                    }
                                    checked={compareWithPrev}
                                    onChange={() =>
                                        setCompareWithPrev(!compareWithPrev)
                                    }
                                />
                            </Box>
                        )
                    )}
                </Box>

                <Box display="flex" alignItems="center" gap={4}>
                    {isGroupOwner && (
                        <ButtonSelect
                            value={selectedGroup}
                            onChange={handleGroupChange}
                            options={[allGroupsOption, ...allGroups]}
                            isLoading={isLoading}
                        />
                    )}
                </Box>
            </Box>

            <Grid
                container
                spacing={2}
                style={{ margin: "-8px", marginTop: 8 }}
            >
                <Grid item xs={12} md={2.4}>
                    <InfoCard
                        iconPath="/ic_sales_outline.svg"
                        label={t("sales.total_sales")}
                        value={
                            <>
                                {numberWithCommas(salesData?.total_sales) || 0}{" "}
                                <CurrencyIcon />
                            </>
                        }
                        tooltipLabel={t("tooltips.sales_total_sales")}
                        isCompare={compareWithPrev}
                        prevVal={calculatePercentageDifference(
                            +salesData?.total_sales || 0,
                            +prevTotals?.total_sales || 0
                        )}
                    />
                </Grid>

                <Grid item xs={12} md={2.4}>
                    <InfoCard
                        iconPath="/ic_profitability.svg"
                        label={t("sales.total_profitability")}
                        value={
                            <>
                                {numberWithCommas(
                                    salesData?.total_profitability
                                ) || 0}{" "}
                                <CurrencyIcon />
                            </>
                        }
                        tooltipLabel={t("tooltips.sales_total_profitability")}
                        isCompare={compareWithPrev}
                        prevVal={calculatePercentageDifference(
                            +salesData?.total_profitability || 0,
                            +prevTotals?.total_profitability || 0
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={2.4}>
                    <InfoCard
                        iconPath="/ic_transactions_outline.svg"
                        label={t("sales.total_transactions")}
                        value={`${salesData?.total_transactions || 0}`}
                        tooltipLabel={t("tooltips.sales_total_transactions")}
                        isCompare={compareWithPrev}
                        prevVal={calculatePercentageDifference(
                            +salesData?.total_transactions || 0,
                            +prevTotals?.total_transactions || 0
                        )}
                    />
                </Grid>

                <Grid item xs={12} md={2.4}>
                    <InfoCard
                        iconPath="/products.svg"
                        label={t("sales.total_products")}
                        value={`${salesData?.total_product || 0} ${t(
                            "common.product_s"
                        )}`}
                        tooltipLabel={t("tooltips.sales_total_products")}
                        isCompare={compareWithPrev}
                        prevVal={calculatePercentageDifference(
                            +salesData?.total_product || 0,
                            +prevTotals?.total_product || 0
                        )}
                    />
                </Grid>

                <Grid item xs={12} md={2.4}>
                    <InfoCard
                        iconPath="/ic_sales_outline.svg"
                        label={t("sales.delivery_sales")}
                        value={`${+(deliveryPerc || 0).toFixed(2)}%`}
                        tooltipLabel={t("tooltips.delivery_sales")}
                        isCompare={compareWithPrev}
                        prevVal={calculatePercentageDifference(
                            +deliveryTotal?.current || 0,
                            +deliveryTotal?.prev || 0
                        )}
                    />
                </Grid>
            </Grid>

            <Stack spacing={2}>
                <Typography variant="h6">{t("sales.salesBy")}</Typography>
                <SalesTabs onTabChange={handleSalesByTabChange} />
            </Stack>

            <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                {salesTab === 0 && (
                    <GridCard
                        padding={0}
                        height={382}
                        boxStyles={{
                            minHeight: "520px",
                            height: "fit-content",
                        }}
                        fullWidth
                    >
                        {isLoadingBranchChart && !multiMeetricData ? (
                            <ChartSkeleton />
                        ) : (
                            <MultiMeetricChart
                                data={multiMeetricData}
                                isCompare={compareWithPrev}
                            />
                        )}
                    </GridCard>
                )}
                {salesTab === 1 && (
                    <GridCard
                        padding={0}
                        boxStyles={{
                            minHeight: "520px",
                            height: "fit-content",
                        }}
                        fullWidth
                    >
                        {isLoadingDayChart && !multiMeetricDataByDate ? (
                            <ChartSkeleton />
                        ) : (
                            <MultiMeetricChartByDate
                                data={multiMeetricDataByDate}
                                isCompare={compareWithPrev}
                            />
                        )}
                    </GridCard>
                )}
            </Grid>
        </>
    );
};

export { Sales };
