import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Box,
    CircularProgress,
    Divider,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { useMutation } from "@tanstack/react-query";
import { services } from "../../services";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CurrencyIcon } from "../../../../common/components/Currency/CurrencyIcon";

const StackPaper = ({ children, ...rest }) => (
    <Stack
        borderRadius={2}
        backgroundColor="white"
        padding={8}
        boxShadow="0 1px 3px hsla(220,6%,43%,0.2)"
        {...rest}
    >
        {children}
    </Stack>
);

const InfoSection = ({ label, value, withDivider = true }) => (
    <Stack spacing={3} width="100%" height="50px">
        <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
        >
            <Typography variant="caption" fontWeight="bold">
                {label}
            </Typography>

            <Typography variant="caption" sx={{ color: "#797d82" }}>
                {value}
            </Typography>
        </Box>

        {withDivider && <Divider flexItem />}
    </Stack>
);

const Customer = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const { customerId, ltv, total, startDate, endDate } = useParams();
    const navigate = useNavigate();
    const [customerDetails, setCustomerDetails] = useState(null);
    const [customerTopProducts, setCustomerTopProducts] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const { mutate: getCustomer, isLoading } = useMutation({
        mutationFn: services.getCustomer,
        onSuccess: (res) => {
            if (!res.error) {
                setSelectedCustomer(res.data.response);
                setCustomerDetails(res.data.response?.customer_details[0]);
                setCustomerTopProducts(
                    res.data.response?.customer_top_products?.map((prod) => ({
                        name: prod.prod_name,
                        img: prod.image,
                    }))
                );
            }
        },
        onError: (err) => console.log(err),
    });

    useEffect(() => {
        getCustomer({
            customer_id: customerId,
            ltv,
            total_transactions: total,
            from_date: startDate,
            till_date: endDate,
        });
    }, []);

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            {isLoading ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress size={25} />
                </Box>
            ) : (
                <>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography variant="h5">
                            {t("customer.customer_data")}
                        </Typography>

                        <Link to="/customers/data">
                            {t("common.customers_back")}
                        </Link>
                    </Box>

                    <Grid container flexWrap="nowrap">
                        <Grid
                            item
                            xs={8}
                            marginRight={isArabic ? 0 : 8}
                            marginLeft={isArabic ? 8 : 0}
                        >
                            <StackPaper
                                backgroundColor="white"
                                padding={8}
                                height={370}
                                spacing={10}
                            >
                                <Typography variant="h6" fontWeight="bold">
                                    {t("customer.customer_data")}
                                </Typography>

                                <Grid container wrap="nowrap" width="100%">
                                    <Grid item xs={5}>
                                        <Stack spacing={5}>
                                            <InfoSection
                                                label={t("common.name")}
                                                value={
                                                    customerDetails?.cust_name
                                                }
                                            />
                                            <InfoSection
                                                label={t("common.gender")}
                                                value={customerDetails?.gender_}
                                            />
                                            {/* <InfoSection
                                            label="Country"
                                            value={selectedCustomer.country}
                                        /> */}
                                            <InfoSection
                                                label={t("common.email")}
                                                value={customerDetails?.email_}
                                                withDivider={false}
                                            />
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={2} />

                                    <Grid item xs={5}>
                                        <Stack spacing={5}>
                                            {/* <InfoSection
                                            label="Last NAME"
                                            value={selectedCustomer.last_name}
                                        /> */}
                                            <InfoSection
                                                label={t("common.phone")}
                                                value={customerDetails?.phone_}
                                            />
                                            {/* <InfoSection
                                            label="City"
                                            value={selectedCustomer.city}
                                        /> */}
                                            <InfoSection
                                                label={t(
                                                    "common.last_transaction"
                                                )}
                                                value={
                                                    customerDetails?.last_transaction
                                                }
                                                withDivider={false}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </StackPaper>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack spacing={8}>
                                <StackPaper
                                    backgroundColor="white"
                                    height={169}
                                    spacing={8}
                                >
                                    <Typography variant="h6" fontWeight="bold">
                                        {t("overview.total_transactions")}
                                    </Typography>
                                    <Typography variant="h6">
                                        {total}
                                    </Typography>
                                </StackPaper>
                                <StackPaper
                                    backgroundColor="white"
                                    height={169}
                                    spacing={8}
                                >
                                    <Typography variant="h6" fontWeight="bold">
                                        {t("overview.ltv")}
                                    </Typography>
                                    <Typography variant="h6">
                                        {isArabic ? (
                                            <>
                                                `{ltv} <CurrencyIcon />`
                                            </>
                                        ) : (
                                            <>
                                                <CurrencyIcon /> {ltv}
                                            </>
                                        )}
                                    </Typography>
                                </StackPaper>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid container flexWrap="nowrap">
                        <Grid
                            item
                            xs={8}
                            marginRight={isArabic ? 0 : 8}
                            marginLeft={isArabic ? 8 : 0}
                        >
                            <StackPaper
                                backgroundColor="white"
                                padding={8}
                                minHeight={300}
                                gap={8}
                            >
                                <Typography variant="h6" fontWeight="bold">
                                    {t("overview.top_products")}
                                </Typography>

                                <Box display="flex" gap={4} flexWrap="wrap">
                                    {customerTopProducts?.map(
                                        (recommendedItem, index) => {
                                            const isLast =
                                                index + 1 ===
                                                customerTopProducts.length;
                                            return (
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    key={recommendedItem.name}
                                                >
                                                    <Stack
                                                        backgroundColor="white"
                                                        border="1px solid #cccccc"
                                                        borderRadius={2}
                                                        width={140}
                                                        height={150}
                                                    >
                                                        <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            backgroundColor="fafafa"
                                                            height="110px"
                                                        >
                                                            {recommendedItem.img ? (
                                                                <img
                                                                    src={
                                                                        recommendedItem.img
                                                                    }
                                                                    width="100%"
                                                                    height="100%"
                                                                />
                                                            ) : (
                                                                <ImageIcon
                                                                    sx={{
                                                                        color: "#cecece",
                                                                    }}
                                                                />
                                                            )}
                                                        </Box>

                                                        <Divider />

                                                        <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            flex={1}
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                fontWeight="bold"
                                                            >
                                                                {
                                                                    recommendedItem.name
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Stack>

                                                    {/* {!isLast && (
                                                        <Box marginX={2}>
                                                            <b> + </b>
                                                        </Box>
                                                    )} */}
                                                </Box>
                                            );
                                        }
                                    )}
                                </Box>
                            </StackPaper>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack spacing={8}>
                                <StackPaper
                                    backgroundColor="white"
                                    height={134}
                                    spacing={5}
                                >
                                    <Typography fontSize={20} fontWeight="bold">
                                        {t("customer.preferre_order")}
                                    </Typography>
                                    <Typography fontSize={18}>
                                        {customerDetails?.preffered_ordertype}
                                    </Typography>
                                </StackPaper>
                                <StackPaper
                                    backgroundColor="white"
                                    height={134}
                                    spacing={5}
                                >
                                    <Typography fontSize={20} fontWeight="bold">
                                        {t("customer.preferre_channel")}
                                    </Typography>
                                    <Typography fontSize={18}>
                                        {customerDetails?.prefferedchannel}
                                    </Typography>
                                </StackPaper>
                            </Stack>
                        </Grid>
                    </Grid>
                </>
            )}
        </Stack>
    );
};

export { Customer };
