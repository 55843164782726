import { Box, Button, FormControlLabel, Checkbox } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

const BranchSelection = ({
    ownerBranches,
    selectedBranches,
    setSelectedBranches,
    setOpenMergeDialog,
}) => {
    const { t } = useTranslation();

    return (
        <Box>
            <Button
                startIcon={<AddIcon />}
                variant="contained"
                color="primary"
                onClick={() => setOpenMergeDialog(true)}
                sx={{
                    textTransform: "none",
                    margin: "auto",
                    marginTop: "14px",
                    backgroundColor: "#00C08B",
                    "&:hover": {
                        backgroundColor: "#00C08B",
                        color: "#FFFFFF",
                    },
                    gap: 2,
                }}
            >
                {t("common.map_branches")}
            </Button>
        </Box>
    );
};

export default BranchSelection;
