import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    DialogActions,
    Button,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { services } from "../../../../manager/services";
import { toast } from "react-toastify";

const DeleteDialog = ({
    open,
    setOpen,
    branchToDelete,
    setMergedBranches,
    refetchMapped,
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const { mutate: deleteBranchMerge } = useMutation({
        mutationFn: services.deleteBranchMerge,
        onSuccess: (res) => {
            if (!res.data.error) {
                toast.success(t("common.done"));
                if (refetchMapped) refetchMapped();
            } else if (res.data.error) {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    const handleConfirmDelete = () => {
        deleteBranchMerge(branchToDelete.id);

        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle sx={{ textAlign: isArabic ? "right" : "left" }}>
                {t("common.confirmDelete")}
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {t("common.deleteMsg")}{" "}
                    <strong>{branchToDelete?.name}</strong>?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{ textTransform: "none" }}
                    onClick={() => setOpen(false)}
                >
                    {t("common.cancel")}
                </Button>
                <Button
                    onClick={handleConfirmDelete}
                    variant="contained"
                    color="error"
                    sx={{ textTransform: "none" }}
                >
                    {t("common.delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialog;
