import { Box, Button, Stack } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SalesPerformance } from "./Performance/SalesPerformance";
import { SalesInsights } from "./Insights/SalesInsights";

export const Sales = () => {
    const { t } = useTranslation();

    const [selectedPage, setSelectedPage] = useState("insights");

    return (
        <Stack padding={8} paddingTop={0} spacing={0} minWidth="100%">
            <Stack>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    padding={0}
                    height={100}
                >
                    <Box display="flex" alignItems="baseline" gap={4}>
                        <Button
                            sx={{
                                color: selectedPage === "insights" && "#00C08B",
                                backgroundColor:
                                    selectedPage === "insights" && "#EFFEF4",
                                "&:hover": {
                                    backgroundColor: "#EFFEF4",
                                },
                                textTransform: "capitalize",
                                borderRadius: "8px",
                            }}
                            onClick={() => setSelectedPage("insights")}
                        >
                            {t("navigation.salesInsights")}
                        </Button>

                        <Button
                            sx={{
                                color:
                                    selectedPage === "performance" && "#00C08B",
                                backgroundColor:
                                    selectedPage === "performance" && "#EFFEF4",
                                "&:hover": {
                                    backgroundColor: "#EFFEF4",
                                    color: "#00C08B",
                                },
                                textTransform: "capitalize",
                                // backgroundColor: "#5BCFDE",
                                borderRadius: "8px",
                            }}
                            onClick={() => setSelectedPage("performance")}
                        >
                            {t("navigation.salesPerformance")}
                        </Button>
                    </Box>
                </Box>

                {selectedPage === "insights" && <SalesInsights />}

                {selectedPage === "performance" && <SalesPerformance />}
            </Stack>
        </Stack>
    );
};
