import { Avatar, Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useContext, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomTextField } from "../../../common/components";
import { getUserStorage } from "../../../common/utils";
import { useMutation } from "@tanstack/react-query";
import { services } from "../services";
import { toast } from "react-toastify";
import { constants } from "../../../config/constants";
import { UserContext } from "../../../common/components/SharedLayout/SharedLayout";
import { WorkingHours } from "./WorkingHours";
import { CurrencyIcon } from "../../../common/components/Currency/CurrencyIcon";

const ChainSettings = () => {
    const { userData } = useContext(UserContext);
    const fileInputRef = useRef(null);

    const user = getUserStorage();
    const { t } = useTranslation();

    const { handleSubmit, control, getValues } = useForm({
        defaultValues: {
            chain_name: "",
            address: "",
            sales_goal: "",
        },
    });

    const [isLogoUpdated, setIsLogoUpdated] = useState(false);
    const [isAddressUpdate, setIsAddressUpdate] = useState(false);
    const [isSalesUpdate, setIsSalesUpdate] = useState(false);
    const [isChainNameUpdate, setIsChainNameUpdate] = useState(false);

    // workaround
    const [updatedValues, setUpdatedValues] = useState({
        address: "",
        sales_goal: "",
        chain_name: "",
    });

    const { mutate: updateCurrentProfile } = useMutation({
        mutationFn: services.updateCurrentProfile,
        onSuccess: (res) => {
            if (!res.data.error) {
                if (isLogoUpdated) {
                    window.location.reload();
                } else {
                    toast.success(t("account.profile_updated"), {
                        hideProgressBar: true,
                    });
                }
            }
        },
        onError: (err) => console.log(err),
    });

    const handleImageRemove = (e) => {
        const formData = new FormData();
        formData.append("logo", null);
        updateCurrentProfile(formData);
    };

    const handleSalesUpdate = () => {
        setIsSalesUpdate(false);
        updateCurrentProfile({ sales_goal: getValues("sales_goal") });
        setUpdatedValues({
            ...updatedValues,
            sales_goal: getValues("sales_goal"),
        });
    };

    const handleLogoUpdate = (e) => {
        const file = e.target.files[0];

        const formData = new FormData();
        formData.append("logo", file);
        updateCurrentProfile(formData);
        setIsLogoUpdated(true);
    };

    const handleChainUpdate = () => {
        setIsChainNameUpdate(false);
        updateCurrentProfile({ chain_name: getValues("chain_name") });
        setUpdatedValues({
            ...updatedValues,
            chain_name: getValues("chain_name"),
        });
    };

    const handleAddressUpdate = () => {
        setIsAddressUpdate(false);
        updateCurrentProfile({ address: getValues("address") });
        setUpdatedValues({ ...updatedValues, address: getValues("address") });
    };

    const onSubmit = (values) => {
        console.log({ values });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={8} pb={40}>
                <Typography>{t("account.manage_chain")}</Typography>

                <Stack gap={4}>
                    <Box display="flex" alignItems="center">
                        <Typography fontSize={14} flex={1} maxWidth={300}>
                            {t("account.logo")}
                        </Typography>
                        <Box flex={1}>
                            <Avatar
                                sx={{ width: 48, height: 48 }}
                                src={`${constants.API}/${user?.merchant?.logo}`}
                            />
                            <input
                                type="file"
                                ref={fileInputRef}
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={handleLogoUpdate}
                            />
                        </Box>

                        <Box gap={4} display="flex">
                            <Button
                                variant="text"
                                sx={{
                                    color: "#5BCFDE",
                                    textTransform: "capitalize",
                                }}
                                onClick={() => fileInputRef.current.click()}
                            >
                                {t("account.change")}
                            </Button>

                            {/* {userData?.merchant?.logo && (
                                <>
                                    <Divider orientation="vertical" flexItem />
                                    <Button
                                        variant="text"
                                        sx={{
                                            color: "#5BCFDE",
                                            textTransform: "capitalize",
                                        }}
                                        onClick={handleImageRemove}
                                    >
                                        Remove
                                    </Button>
                                </>
                            )} */}
                        </Box>
                    </Box>

                    <Divider />

                    <Box display="flex" alignItems="center">
                        <Typography fontSize={14} flex={1} maxWidth={300}>
                            {t("account.chain_name")}
                        </Typography>
                        <Box flex={1}>
                            {isChainNameUpdate ? (
                                <Box width={400}>
                                    <Controller
                                        name="chain_name"
                                        control={control}
                                        render={({ field }) => (
                                            <CustomTextField
                                                fullWidth
                                                placeholder={t(
                                                    "account.chain_name"
                                                )}
                                                variant="outlined"
                                                {...field}
                                            />
                                        )}
                                    />
                                </Box>
                            ) : (
                                <Typography
                                    sx={{ color: "#64748B", fontSize: 14 }}
                                >
                                    {updatedValues.chain_name ||
                                        userData?.merchant?.chain_name}
                                </Typography>
                            )}
                        </Box>

                        <Box gap={4} display="flex">
                            {isChainNameUpdate ? (
                                <Button
                                    variant="text"
                                    sx={{
                                        color: "#5BCFDE",
                                        textTransform: "capitalize",
                                    }}
                                    onClick={handleChainUpdate}
                                >
                                    {t("account.save")}
                                </Button>
                            ) : (
                                <Button
                                    variant="text"
                                    sx={{
                                        color: "#5BCFDE",
                                        textTransform: "capitalize",
                                    }}
                                    onClick={() => setIsChainNameUpdate(true)}
                                >
                                    {t("account.update")}
                                </Button>
                            )}
                        </Box>
                    </Box>
                    <Divider />

                    <Box display="flex" alignItems="center">
                        <Typography fontSize={14} flex={1} maxWidth={300}>
                            {t("account.address")}
                        </Typography>
                        <Box flex={1}>
                            {isAddressUpdate ? (
                                <Box width={400}>
                                    <Controller
                                        name="address"
                                        control={control}
                                        render={({ field }) => (
                                            <CustomTextField
                                                fullWidth
                                                placeholder={t(
                                                    "account.address"
                                                )}
                                                variant="outlined"
                                                {...field}
                                            />
                                        )}
                                    />
                                </Box>
                            ) : (
                                <Typography
                                    sx={{ color: "#64748B", fontSize: 14 }}
                                >
                                    {updatedValues.address ||
                                        userData?.merchant?.address}
                                </Typography>
                            )}
                        </Box>
                        <Box gap={4} display="flex">
                            {isAddressUpdate ? (
                                <Button
                                    variant="text"
                                    sx={{
                                        color: "#5BCFDE",
                                        textTransform: "capitalize",
                                    }}
                                    onClick={handleAddressUpdate}
                                >
                                    {t("account.save")}
                                </Button>
                            ) : (
                                <Button
                                    variant="text"
                                    sx={{
                                        color: "#5BCFDE",
                                        textTransform: "capitalize",
                                    }}
                                    onClick={() => setIsAddressUpdate(true)}
                                >
                                    {t("account.update")}
                                </Button>
                            )}
                        </Box>
                    </Box>

                    <Divider />

                    <Box display="flex" alignItems="center">
                        <Typography fontSize={14} flex={1} maxWidth={300}>
                            {t("account.sales_goal")}
                        </Typography>
                        <Box flex={1}>
                            {isSalesUpdate ? (
                                <Box width={400}>
                                    <Controller
                                        name="sales_goal"
                                        control={control}
                                        render={({ field }) => (
                                            <CustomTextField
                                                fullWidth
                                                placeholder={t(
                                                    "account.sales_goal"
                                                )}
                                                variant="outlined"
                                                type="number"
                                                {...field}
                                            />
                                        )}
                                    />
                                </Box>
                            ) : (
                                <Typography
                                    sx={{ color: "#64748B", fontSize: 14 }}
                                >
                                    {updatedValues.sales_goal ||
                                        userData?.merchant?.sales_goal ||
                                        0}{" "}
                                    <CurrencyIcon />
                                </Typography>
                            )}
                        </Box>

                        <Box gap={4} display="flex">
                            {isSalesUpdate ? (
                                <Button
                                    variant="text"
                                    sx={{
                                        color: "#5BCFDE",
                                        textTransform: "capitalize",
                                    }}
                                    onClick={handleSalesUpdate}
                                >
                                    {t("account.save")}
                                </Button>
                            ) : (
                                <Button
                                    variant="text"
                                    sx={{
                                        color: "#5BCFDE",
                                        textTransform: "capitalize",
                                    }}
                                    onClick={() => setIsSalesUpdate(true)}
                                >
                                    {t("account.update")}
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Stack>
            </Stack>
        </form>
    );
};

export { ChainSettings };
