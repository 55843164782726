import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import {
    Box,
    CircularProgress,
    Grid,
    Stack,
    Typography,
    useTheme,
    useMediaQuery,
    Button,
} from "@mui/material";
// import IosShareIcon from "@mui/icons-material/IosShare";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import {
    ButtonSelect,
    // ButtonSelect,
    GridCard,
    PageHeader,
    RangePicker,
} from "../../../common/components";
import { services } from "../services";
import { SalesGoals } from "./components/SalesGoal";
import { TopLTV } from "./components/TopLTV";
import { TopProducts } from "./components/TopProducts";
import { OrderTypes } from "./components/OrderTypes";
import { Categories } from "./components/CategoriesPast";
import { PaymentMethods } from "./components/PaymentMethods";
import { TrendingHours } from "./components/TrendingHours";
import { InfoCard } from "./components/InfoCard";
import { constants } from "../../../config/constants";
import { getToken, getUserStorage } from "../../../common/utils";
import { BranchSales } from "./components/BranchesSales";
import { numberWithCommas } from "../../../common/utils/numberWithCommas";
import { toast } from "react-toastify";
import { ExportButton } from "../../../common/components/Export/Export";
import { CurrencyIcon } from "../../../common/components/Currency/CurrencyIcon";

const Overview = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.only("xs"));
    const user = getUserStorage();
    const isOwner = user.role === 1;
    const isGroupOwner = user.role === 9;

    const [period, setPeriod] = useState({
        startDate: null,
        endDate: format(new Date(), "y-M-d"),
    });
    const [overviewData, setOverviewData] = useState(null);
    const [orderTypesData, setOrderTypesData] = useState(null);
    const [categoryData, setCategoryData] = useState(null);
    const [paymentData, setPaymentData] = useState(null);
    const [topProducts, setTopProducts] = useState(null);
    const [topCustomers, setTopCustomers] = useState(null);
    const [branchesSales, setBranchesSales] = useState(null);

    const [trendingHoursData, setTrendingHoursData] = useState([]);

    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
    const [overviewPDF, setOverviewPDF] = useState(null);

    const isManagerView = user.manager_view;
    const allBranchesOption = isManagerView ? user?.branch[0].branch_id : "all";

    const userGroups = user.group;
    const allGroups =
        userGroups?.map((group) => ({
            id: group.merchant_id,
            label: group.name,
        })) || [];
    const allGroupsOption = { id: "all", label: t("common.all_groups") };
    const [selectedGroup, setSelectedGroup] = useState(allGroupsOption.id);

    const { mutate: getOverviewData, isLoading } = useMutation({
        mutationFn: services.getOverviewData,
        onSuccess: (res) => {
            if (res.data.response && !res.data.error) {
                const overviewData = res.data.response;
                setOverviewData(overviewData);

                const orderTypesTotal = getTotalCount(
                    res.data.response.order_analysis
                );
                const categoryTotal = getTotalCount(
                    res.data.response.category_analysis
                );
                const paymentTotal = getTotalCount(
                    res.data.response.payment_analysis
                );
                const branchSalesTotal = getTotalCount(
                    res.data.response?.branch_sales
                );

                const refinedTrendingHours = refineTrendingHours(
                    res.data.response.hourly_trending || []
                );
                const refined_trending_hours = refinedTrendingHours.map(
                    (hourData) => ({
                        name: constants.hours[`${hourData.hour_number}`],
                        uv: hourData.total_sales,
                    })
                );
                const top_products = res.data.response?.top_products?.splice(
                    0,
                    4
                );
                const top_customers = res.data.response?.top_customers?.splice(
                    0,
                    4
                );
                const branches_sales = res.data.response.branch_sales.map(
                    (branch, index) => ({
                        name: branch.branch_name,
                        value: (
                            ((+branch.total_sales / branchSalesTotal) * 1000) /
                            10
                        ).toFixed(2),
                        sales: numberWithCommas(branch.total_sales),
                        fill: constants.pieColors[index],
                        // innerRadius: (index + 1) * 10,
                        outerRadius: 115 + (index + 1) * 10,
                    })
                );
                const order_types = res.data.response.order_analysis.map(
                    (order, index) => ({
                        name: order.description,
                        value: (
                            ((order.total_sales / orderTypesTotal) * 1000) /
                            10
                        ).toFixed(2),
                        fill: constants.pieColors[index],
                        innerRadius: (index + 1) * 10,
                        outerRadius: 100 + (index + 1) * 12,
                    })
                );
                const category_data = res.data.response.category_analysis.map(
                    (order, index) => ({
                        name: order.category_name,
                        value: (
                            ((+order.total_sales / categoryTotal) * 1000) /
                            10
                        ).toFixed(2),
                        fill: constants.pieColors[index],
                        innerRadius: (index + 1) * 10,
                        outerRadius: 90 + (index + 1) * 6,
                    })
                );
                const payment_data = res.data.response.payment_analysis.map(
                    (payment, index) => {
                        return {
                            name: payment.payment_type,
                            value: (
                                ((payment.total_sales / paymentTotal) * 1000) /
                                10
                            ).toFixed(2),
                            fill: constants.pieColors[index],
                        };
                    }
                );

                setTrendingHoursData(refined_trending_hours);
                setTopProducts(top_products);
                setTopCustomers(top_customers);
                setOrderTypesData(order_types);
                setCategoryData(category_data);
                setPaymentData(payment_data);
                if (isOwner) setBranchesSales(branches_sales);

                setOverviewPDF({
                    overviewData,
                    refined_trending_hours,
                    top_products,
                    top_customers,
                    order_types,
                    category_data,
                    payment_data,
                    branches_sales,
                    period,
                });

                // localStorage.setItem(
                //     "overview-pdf",
                //     JSON.stringify({
                //         overviewData,
                //         refined_trending_hours,
                //         top_products,
                //         top_customers,
                //         order_types,
                //         category_data,
                //         payment_data,
                //         branches_sales,
                //     })
                // );
            }

            if (res.data.error) {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });
    const refineData = (dataArr) => {
        if (!dataArr) return;

        const mainChartData = [];
        const otherChartData = [];

        dataArr.forEach((item) => {
            if (item.value > 15) mainChartData.push({ ...item });
            else otherChartData.push(item);
        });

        let totalOtherValue = 0;
        let otherString = `\nOther \n `;
        if (otherChartData.length) {
            otherChartData.forEach((otherItem, i) => {
                totalOtherValue = totalOtherValue + otherItem.value;
                otherString =
                    otherString +
                    `- ${otherItem.name} (${otherItem.value}%) \n`;
            });
        }

        if (otherChartData.length)
            return [
                ...mainChartData,
                {
                    ...otherChartData[0],
                    name: "Other",
                    label: otherString,
                    value: totalOtherValue,
                },
            ].map((item, index) => ({
                ...item,
                fill: constants.pieColors[index],
            }));

        return mainChartData;
    };

    const refineTrendingHours = (arr) => {
        const refined = [];
        for (let i = 0; i < 24; i++) {
            const hour = arr.find((item) => i === +item.hour_number);
            if (hour) refined.push(hour);
            else refined.push({ hour_number: i.toString(), total_sales: 0 });
        }

        return refined;
    };

    const handleDatesChange = ({ startDate, endDate }) => {
        if (startDate !== "1970-1-1") {
            setPeriod({ startDate, endDate });
            if (isOwner) {
                getOverviewData({
                    from_date: startDate,
                    till_date: endDate,
                    [`${isGroupOwner ? "merchant_filter" : "branch_id"}`]:
                        isGroupOwner ? selectedGroup : allBranchesOption,
                });
            } else {
                getOverviewData({
                    from_date: startDate,
                    till_date: endDate,
                    merchant_filter: isGroupOwner ? "all" : null,
                });
            }
        }
    };

    const handleGroupChange = (e) => {
        let newSelectedGroupValue = e.target.value;
        const lastElement =
            newSelectedGroupValue[newSelectedGroupValue.length - 1];
        if (lastElement === "all" || !newSelectedGroupValue.length)
            newSelectedGroupValue = "all";
        else
            newSelectedGroupValue = newSelectedGroupValue.filter(
                (i) => i !== "all"
            );

        setSelectedGroup(newSelectedGroupValue);

        getOverviewData({
            from_date: period.startDate,
            till_date: period.endDate,
            merchant_filter: newSelectedGroupValue,
        });
    };

    useEffect(() => {
        window.pendo.identify({
            visitor: {
                id: user.id, // Required if user is logged in, default creates anonymous ID
                email: user.email, // Recommended if using Pendo Feedback, or NPS Email
                // full_name:    // Recommended if using Pendo Feedback
                // role:         // Optional

                // You can add any additional visitor level key-values here,
                // as long as it's not one of the above reserved names.
            },
        });

        if (period.startDate) {
            if (isOwner) {
                getOverviewData({
                    from_date: period.startDate,
                    till_date: format(new Date(), "y-M-d"),
                    branch_id: allBranchesOption,
                });
            } else {
                getOverviewData({
                    from_date: period.startDate,
                    till_date: format(new Date(), "y-M-d"),
                });
            }

            setTrendingHoursData();
        }
    }, [isManagerView]);

    function getTotalCount(arr, key = "total_sales") {
        let count = 0;
        arr.forEach((i) => (count = count + +i[key]));

        return count;
    }

    const handleButtonClick = () => {
        // Track an event when the button is clicked
        window.pendo.track("Button Clicked", {
            buttonName: "MyButton",
        });
    };

    useEffect(() => {
        window?.pendo?.pageLoad({
            name: "Overview Page",
        });
    }, []);

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <PageHeader
                title={
                    isOwner && !isManagerView
                        ? t("overview.title")
                        : t("overview.overview")
                }
            />

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <RangePicker
                    onChange={(value) => handleDatesChange(value)}
                    isLoading={isLoading}
                />

                <Box display="flex" alignItems="center" gap={2}>
                    {isGroupOwner && (
                        <ButtonSelect
                            value={selectedGroup}
                            onChange={handleGroupChange}
                            options={[allGroupsOption, ...allGroups]}
                            isLoading={isLoading}
                            isMulti
                        />
                    )}

                    <ExportButton
                        overviewPDF={overviewPDF}
                        pageName="overviewPDF"
                        isLoading={isLoading}
                    />
                </Box>
            </Box>

            <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                <Grid item xs={12} md={6}>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6}>
                            <InfoCard
                                label={t("overview.total_sales")}
                                value={
                                    <>
                                        {numberWithCommas(
                                            overviewData?.totals.total_sales
                                        ) || 0}{" "}
                                        <CurrencyIcon />
                                    </>
                                }
                                iconPath="/ic_sales_outline.svg"
                                tooltipLabel={t(
                                    "tooltips.overview_total_sales"
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InfoCard
                                label={t("overview.total_customers")}
                                value={
                                    overviewData?.totals.total_customers || 0
                                }
                                iconPath="/ic_customers_many.svg"
                                tooltipLabel={t(
                                    "tooltips.overview_total_customers"
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InfoCard
                                label={t("overview.total_transactions")}
                                value={
                                    overviewData?.totals.total_transactions || 0
                                }
                                iconPath="/ic_transactions.svg"
                                tooltipLabel={t(
                                    "tooltips.overview_total_transactions"
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InfoCard
                                label={t("overview.avg_basket_size")}
                                value={
                                    <>
                                        {numberWithCommas(
                                            overviewData?.totals.avg_basket_size
                                        ) || 0}{" "}
                                        <CurrencyIcon />
                                    </>
                                }
                                iconPath="/ic_basket.svg"
                                tooltipLabel={t(
                                    "tooltips.overview_avg_basket_size"
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <GridCard
                    gridProps={{ xs: 12, md: 6 }}
                    padding={0}
                    height={372}
                    boxStyles={{ minHeight: "372px", height: "fit-content" }}
                >
                    <SalesGoals period={period} />
                </GridCard>
            </Grid>

            <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 0,
                    marginBottom: 0,
                }}
                sx={{
                    minHeight: 400,
                    height: "fit-content",
                }}
            >
                <GridCard
                    gridProps={{ xs: 12, md: 6 }}
                    // gutter
                    padding={0}
                    height="inherit"
                >
                    {isOwner ? (
                        <BranchSales data={branchesSales} />
                    ) : (
                        <TopLTV data={topCustomers} />
                    )}
                </GridCard>

                <GridCard
                    gridProps={{ xs: 12, md: 6 }}
                    padding={0}
                    height="inherit"
                >
                    {isOwner ? (
                        <PaymentMethods data={paymentData} />
                    ) : (
                        <TopProducts data={topProducts} />
                    )}
                </GridCard>
            </Grid>

            <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 0,
                    marginBottom: 0,
                }}
            >
                <GridCard
                    gridProps={{ xs: 12, md: 6 }}
                    padding={0}
                    height={360}
                    boxStyles={{ minHeight: 360, height: "fit-content" }}
                >
                    {isOwner ? (
                        <TopLTV data={topCustomers} />
                    ) : (
                        <Categories data={categoryData} />
                    )}
                </GridCard>

                <GridCard
                    gridProps={{ xs: 12, md: 6 }}
                    padding={0}
                    height={360}
                    boxStyles={{ minHeight: 360, height: "fit-content" }}
                >
                    {isOwner ? (
                        <TopProducts data={topProducts} />
                    ) : (
                        <OrderTypes data={orderTypesData} />
                    )}
                </GridCard>
            </Grid>

            {isOwner && (
                <Grid
                    container
                    spacing={6}
                    style={{
                        margin: isArabic ? 0 : "-24px",
                        marginTop: 0,
                        marginBottom: 0,
                    }}
                    sx={{
                        minHeight: 300,
                        height: "fit-content",
                    }}
                >
                    <GridCard
                        padding={0}
                        gridProps={{ xs: 12, md: 6 }}
                        height="inherit"
                        boxStyles={{ minHeight: "100%" }}
                    >
                        <Categories data={categoryData} />
                    </GridCard>

                    <GridCard
                        padding={0}
                        gridProps={{ xs: 12, md: 6 }}
                        height="inherit"
                    >
                        <OrderTypes data={orderTypesData} />
                    </GridCard>
                </Grid>
            )}

            <Grid container spacing={0}>
                <GridCard
                    fullWidth
                    padding={0}
                    boxStyles={{ minHeight: 360, height: "fit-content" }}
                >
                    <TrendingHours data={trendingHoursData} />
                </GridCard>
            </Grid>

            {!isOwner && (
                <Grid
                    container
                    spacing={6}
                    style={{
                        margin: isArabic ? 0 : "-24px",
                        marginTop: 0,
                        marginBottom: 0,
                    }}
                >
                    <GridCard
                        gridProps={{ xs: 12, md: 6 }}
                        padding={0}
                        height="inherit"
                    >
                        <PaymentMethods data={paymentData} />
                    </GridCard>

                    <Grid item />
                </Grid>
            )}
        </Stack>
    );
};

export { Overview };
