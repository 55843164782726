import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { VictoryPie } from "victory";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
    FilterSelect,
    GridCard,
    RangePicker,
} from "../../../../common/components";
import { InfoCard } from "./InfoCard";
import { services } from "../../services";
import { constants } from "../../../../config/constants";
import { numberWithCommas } from "../../../../common/utils/numberWithCommas";
import { getToken, getUserStorage } from "../../../../common/utils";
import { ExportButton } from "../../../../common/components/Export/Export";
import { CurrencyIcon } from "../../../../common/components/Currency/CurrencyIcon";

const BasketProduct = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
    const [overviewPDF, setOverviewPDF] = useState({});
    const [period, setPeriod] = useState({
        startDate: new Date("1/1/2000").toLocaleDateString("en"),
        endDate: format(new Date(), "yyyy-MM-dd"),
    });

    const [productDetails, setProductDetails] = useState(null);
    const [topProducts, setTopProducts] = useState(null);
    const [productsPieData, setProductsPieData] = useState(null);

    const filterItems = [
        { label: t("common.dine_in"), value: "DINING", key: "type" },
        { label: t("common.pickup"), value: "TAKE_AWAY", key: "type" },
        {
            label: t("common.drive_through"),
            value: "DRIVE_THROUGH",
            key: "type",
        },
        { label: t("common.delivery"), value: "DELIVERY", key: "type" },
    ];
    const [orderType, setOrderType] = useState(filterItems[0].value);

    const { data } = useQuery({
        queryKey: ["allProducts"],
        queryFn: services.getMerchantProducts,
    });

    const { mutate: getBasketProduct, isLoading } = useMutation({
        mutationFn: services.getBasketProduct,
        onSuccess: (res) => {
            if (res.data.response) {
                const response = res.data.response;

                const top_products = res.data.response.basket_percentage;
                const pie_data = res.data.response.basket_percentage.map(
                    (p) => ({
                        name: `${(+p.percentage).toFixed(0)}%`,
                        perc: (+p.percentage).toFixed(0),
                        y: p.percentage % 85.01111,
                    })
                );

                const product_details = {
                    amount_of_sales:
                        response?.basket_totals[0]?.amount_of_sales || 0,
                    avg_basket_quantity:
                        response?.basket_totals[0]?.avg_basket_quantity || 0,
                    avg_basket_value:
                        response?.basket_totals[0]?.avg_basket_value || 0,
                    number_of_customer:
                        response?.basket_totals[0]?.number_of_customer || 0,
                };

                setProductDetails(product_details);
                setTopProducts(top_products);
                setProductsPieData(pie_data);

                setOverviewPDF({
                    product_details,
                    top_products,
                    pie_data,
                    period,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    const [selectedProduct, setSelectedProduct] = useState("");

    const handleDatesChange = ({ startDate, endDate }) => {
        if (startDate !== "1970-1-1") {
            setPeriod({ startDate, endDate });

            getBasketProduct({
                from_date: startDate,
                till_date: endDate,
                order_type: orderType,
                product_id: selectedProduct.id,
            });
        }
    };

    const handleFilterChange = (value) => {
        setOrderType(value);

        getBasketProduct({
            from_date: period.startDate,
            till_date: period.endDate,
            order_type: value,
            product_id: selectedProduct.id,
        });
    };

    const handleProductSelect = (newSelectedProduct) => {
        setSelectedProduct(newSelectedProduct);

        getBasketProduct({
            from_date: period.startDate,
            till_date: period.endDate,
            order_type: orderType,
            product_id: newSelectedProduct.id,
        });
    };

    const getProductNames = useCallback(() => {
        return data?.data?.response.map((p) => ({
            id: p.product_id,
            label: p.name,
        }));
    }, [data?.data?.response]);

    const allProducts = getProductNames(data?.data?.response) || [];

    useEffect(() => {
        window.pendo.pageLoad({
            name: "Basket Product Page",
        });
    }, []);

    return (
        <Stack spacing={8} minWidth="100%" marginBottom={12}>
            <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" alignItems="center" gap={3}>
                    <RangePicker
                        onChange={(value) => handleDatesChange(value)}
                        isLoading={isLoading}
                    />
                </Box>

                <Box display="flex" alignItems="center" gap={4}>
                    <FilterSelect
                        value={orderType}
                        // label={t("common.order_type")}
                        label={t(`common.${orderType}`)}
                        items={filterItems}
                        onChange={({ value }) => handleFilterChange(value)}
                        icon={KeyboardArrowDownIcon}
                        isLoading={isLoading}
                    />
                    <ExportButton
                        overviewPDF={{
                            ...overviewPDF,
                            selected_product: selectedProduct,
                            all_products: allProducts,
                            isArabic,
                            printDate: `${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`,
                        }}
                        pageName="BasketProductPDF"
                        isLoading={isLoading}
                    />
                </Box>
            </Box>

            <Grid container>
                <GridCard fullWidth padding={0} height={900}>
                    <Stack>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            padding={6}
                            height={74}
                        >
                            <Typography
                                fontWeight="bold"
                                fontSize={20}
                                color="#202020"
                            >
                                {t("basket.basket_analysis")}
                            </Typography>
                        </Box>

                        <Divider />

                        <Stack spacing={6}>
                            <Stack
                                width="100%"
                                minHeight={600}
                                backgroundColor="white"
                                borderRadius={1}
                                padding={8}
                                spacing={4}
                            >
                                <Typography fontSize={16} color="#06262D">
                                    {t("common.enter_product_name")}
                                </Typography>

                                <Autocomplete
                                    value={selectedProduct}
                                    onChange={(e, value) =>
                                        handleProductSelect(value)
                                    }
                                    disablePortal
                                    id="combo-box-demo"
                                    options={allProducts}
                                    sx={{ width: 400 }}
                                    disableClearable
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("common.select_product")}
                                        />
                                    )}
                                />

                                {selectedProduct.id && (
                                    <>
                                        {!!topProducts?.length && (
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                maxWidth="1800px"
                                            >
                                                <Stack gap={10}>
                                                    <Typography
                                                        fontSize={20}
                                                        color="#06262D"
                                                    >
                                                        {t(
                                                            "basket.customer_who_order"
                                                        )}{" "}
                                                        {selectedProduct.label}{" "}
                                                        {t("basket.also_order")}
                                                    </Typography>

                                                    <Stack
                                                        gap={3}
                                                        paddingLeft={8}
                                                    >
                                                        {topProducts.map(
                                                            (topProduct) => (
                                                                <Box
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    gap={3}
                                                                >
                                                                    <Box
                                                                        width={
                                                                            8
                                                                        }
                                                                        height={
                                                                            8
                                                                        }
                                                                        borderRadius="50%"
                                                                        backgroundColor="#D1D5DB"
                                                                    />

                                                                    <Typography variant="body1">
                                                                        <b>
                                                                            {isArabic
                                                                                ? `%${topProduct.percentage}`
                                                                                : `${topProduct.percentage}%`}
                                                                        </b>{" "}
                                                                        {t(
                                                                            "basket.of_all_cus_who_order"
                                                                        )}{" "}
                                                                        <b>
                                                                            {
                                                                                selectedProduct.label
                                                                            }
                                                                        </b>{" "}
                                                                        {t(
                                                                            "basket.also_order"
                                                                        )}{" "}
                                                                        <b>
                                                                            {
                                                                                topProduct.item
                                                                            }
                                                                        </b>
                                                                    </Typography>
                                                                </Box>
                                                            )
                                                        )}
                                                    </Stack>
                                                </Stack>

                                                <Box>
                                                    <svg
                                                        width={450}
                                                        height={450}
                                                    >
                                                        <circle
                                                            cx={225}
                                                            cy={227}
                                                            r={50}
                                                            fill="#CCCCCC"
                                                        />
                                                        <text
                                                            color="#06262D"
                                                            x="50%"
                                                            y="50%"
                                                            dominant-baseline="middle"
                                                            text-anchor="middle"
                                                        >
                                                            {
                                                                selectedProduct.label
                                                            }
                                                        </text>
                                                        <VictoryPie
                                                            width={450}
                                                            height={450}
                                                            standalone={false}
                                                            data={
                                                                productsPieData
                                                            }
                                                            labelRadius={({
                                                                innerRadius,
                                                            }) =>
                                                                innerRadius + 25
                                                            }
                                                            radius={({
                                                                datum,
                                                                index,
                                                            }) => {
                                                                return (
                                                                    115 +
                                                                    +datum.perc
                                                                );
                                                            }}
                                                            innerRadius={60}
                                                            startAngle={90}
                                                            endAngle={-90}
                                                            style={{
                                                                labels: {
                                                                    fill: "white",
                                                                    fontSize: 16,
                                                                },
                                                            }}
                                                            labels={(value) =>
                                                                value.datum.name
                                                            }
                                                            colorScale={
                                                                constants.pieColors
                                                            }
                                                        />
                                                    </svg>
                                                </Box>
                                            </Box>
                                        )}

                                        <Divider />

                                        <Stack gap={8} marginTop={-8}>
                                            <Typography
                                                variant="h6"
                                                fontWeight="bold"
                                            >
                                                {t("basket.more_details")}
                                            </Typography>

                                            <Grid
                                                container
                                                spacing={0}
                                                rowSpacing={6}
                                            >
                                                <Grid item xs={6} md={3}>
                                                    <InfoCard
                                                        iconPath="/ic_customers_outline.svg"
                                                        containerStyles={{
                                                            midWidth: 240,
                                                            marginRight:
                                                                isArabic
                                                                    ? 0
                                                                    : 6,
                                                            marginLeft: isArabic
                                                                ? 6
                                                                : 0,
                                                        }}
                                                        label={t(
                                                            "basket.number_of_customers"
                                                        )}
                                                        value={
                                                            productDetails?.number_of_customer ||
                                                            0
                                                        }
                                                    />
                                                </Grid>

                                                <Grid item xs={6} md={3}>
                                                    <InfoCard
                                                        iconPath="/ic_sales_outline.svg"
                                                        containerStyles={{
                                                            midWidth: 240,
                                                            marginRight:
                                                                isArabic
                                                                    ? 0
                                                                    : 6,
                                                            marginLeft: isArabic
                                                                ? 6
                                                                : 0,
                                                        }}
                                                        label={t(
                                                            "basket.amount_of_sales"
                                                        )}
                                                        value={
                                                            <>
                                                                {numberWithCommas(
                                                                    productDetails?.amount_of_sales
                                                                ) || 0}{" "}
                                                                <CurrencyIcon />
                                                            </>
                                                        }
                                                    />
                                                </Grid>

                                                <Grid item xs={6} md={3}>
                                                    <InfoCard
                                                        iconPath="/ic_basket_outline.svg"
                                                        containerStyles={{
                                                            midWidth: 240,
                                                            marginRight:
                                                                isArabic
                                                                    ? 0
                                                                    : 6,
                                                            marginLeft: isArabic
                                                                ? 6
                                                                : 0,
                                                        }}
                                                        label={t(
                                                            "basket.avg_basket_value"
                                                        )}
                                                        value={
                                                            <>
                                                                {numberWithCommas(
                                                                    productDetails?.avg_basket_value
                                                                ) || 0}{" "}
                                                                <CurrencyIcon />
                                                            </>
                                                        }
                                                    />
                                                </Grid>

                                                <Grid item xs={6} md={3}>
                                                    <InfoCard
                                                        iconPath="/ic_quantity.svg"
                                                        label={t(
                                                            "basket.avg_basket_quantity"
                                                        )}
                                                        value={`${
                                                            productDetails?.avg_basket_quantity ||
                                                            0
                                                        }`}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </>
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </GridCard>
            </Grid>
        </Stack>
    );
};

export { BasketProduct };
