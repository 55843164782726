import {
    Box,
    Button,
    Divider,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    tableCellClasses,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { constants } from "../../../../config/constants";
import { numberWithCommas } from "../../../../common/utils/numberWithCommas";
import { GridCardHeader } from "../../../../common/components";
import { CurrencyIcon } from "../../../../common/components/Currency/CurrencyIcon";

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#FAFCFE",
        color: "#999999",
    },
    [`&.${tableCellClasses.root}`]: {
        lineHeight: 1,
        paddingLeft: 24,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {},
    [`&.${tableCellClasses.root}`]: {
        lineHeight: 1,
        paddingLeft: 24,
    },
}));

const TopProducts = ({ data }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.only("xs"));
    const navigate = useNavigate();

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <GridCardHeader title={t("overview.top_products")} />

                <Button
                    variant="outlined"
                    sx={{
                        height: 26,
                        minWidth: 83,
                        borderRadius: 2,
                        color: "#434854",
                        borderColor: "#edecef",
                        textTransform: "capitalize",
                        fontSize: isMobileView && 12,
                    }}
                    onClick={() => navigate("/menu/products")}
                >
                    {t("common.view_all")}
                </Button>
            </Box>

            <Divider />

            <Box>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableHeadCell colSpan={6}>
                                    {t("overview.product")}
                                </StyledTableHeadCell>
                                <StyledTableHeadCell align="left" colSpan={4}>
                                    {t("overview.class")}
                                </StyledTableHeadCell>
                                <StyledTableHeadCell align="left">
                                    {t("overview.price")}
                                </StyledTableHeadCell>
                                <StyledTableHeadCell align="left">
                                    {t("overview.total_sales")}
                                </StyledTableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!data?.length &&
                                Array.from(Array(4).keys()).map((i) => (
                                    <TableRow
                                        key={i}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                            colSpan={6}
                                        >
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="left"
                                            colSpan={4}
                                        >
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            -
                                        </StyledTableCell>
                                    </TableRow>
                                ))}

                            {data?.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <StyledTableCell
                                        component="th"
                                        scope="row"
                                        colSpan={6}
                                    >
                                        {row.product_name}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="left"
                                        colSpan={4}
                                        sx={{ color: "#71767C" }}
                                    >
                                        {" "}
                                        {row.class}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {numberWithCommas(row.product_price)}{" "}
                                        <CurrencyIcon />
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="left"
                                        sx={{ color: "#71767C" }}
                                    >
                                        {numberWithCommas(row.total_sales)}{" "}
                                        <CurrencyIcon />
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Stack>
    );
};

export { TopProducts };
