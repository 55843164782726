import {
    Box,
    Button,
    Divider,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    tableCellClasses,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { constants } from "../../../../config/constants";
import { numberWithCommas } from "../../../../common/utils/numberWithCommas";
import { GridCardHeader } from "../../../../common/components";
import { format } from "date-fns";
import { CurrencyIcon } from "../../../../common/components/Currency/CurrencyIcon";

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#FAFCFE",
        color: "#999999",
        fontSize: "16px",
    },
    [`&.${tableCellClasses.root}`]: {
        lineHeight: 1,
        paddingLeft: 24,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {},
    [`&.${tableCellClasses.root}`]: {
        lineHeight: 1,
        paddingLeft: 24,
    },
}));

const TabelHeadWithIcon = ({ title, iconPath }) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            gap={2}
            textTransform="capitalize"
        >
            <img src={iconPath} alt="sideNavBarIcon" width="20px" />
            {title}
        </Box>
    );
};

function generateFakeDataArray(numRows) {
    const fakeData = [];
    const timeOptions = ["AM", "PM"];

    for (let i = 0; i < numRows; i++) {
        const randomHour = Math.floor(Math.random() * 12) + 1;
        const randomMinute = Math.floor(Math.random() * 60);
        const randomTime =
            timeOptions[Math.floor(Math.random() * timeOptions.length)];

        const time = `${String(randomHour).padStart(2, "0")}:${String(
            randomMinute
        ).padStart(2, "0")} ${randomTime}`;

        const date = format(new Date(), "dd/MM/yyyy");

        const totalSales = Math.floor(Math.random() * 1000000); // Random total sales amount
        const profitability = Math.floor(Math.random() * 500000); // Random profitability
        const transactions = Math.floor(Math.random() * 500); // Random number of transactions
        const products = Math.floor(Math.random() * 100); // Random number of products
        const newCustomers = Math.floor(Math.random() * 50); // Random number of new customers
        const customers = Math.floor(Math.random() * 100); // Random number of total customers

        const row = {
            data: date,
            total_sales: totalSales,
            profitability: profitability,
            transactions: transactions,
            products: products,
            new_customers: newCustomers,
            customers: customers,
        };

        fakeData.push(row);
    }

    return fakeData;
}

const numRows = 10; // Change this to the number of rows you want
const fakeDataArray = generateFakeDataArray(numRows);

const HistoryData = ({ data }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.only("xs"));
    const navigate = useNavigate();

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <GridCardHeader title="History Data" />
                {/* <GridCardHeader title={t("forcast.history_data")} /> */}
            </Box>

            <Divider />

            <Box>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableHeadCell colSpan={6}>
                                    Date
                                    {/* {t("forcast.date")} */}
                                </StyledTableHeadCell>
                                <StyledTableHeadCell align="left" colSpan={4}>
                                    {/* Total Sales */}
                                    <TabelHeadWithIcon
                                        title={t("common.total_sales")}
                                        iconPath="/ic_sales_outline.svg"
                                    />
                                </StyledTableHeadCell>
                                <StyledTableHeadCell align="left">
                                    <TabelHeadWithIcon
                                        title={t("common.profitability")}
                                        iconPath="/ic_profitability.svg"
                                    />
                                </StyledTableHeadCell>
                                <StyledTableHeadCell align="left">
                                    <TabelHeadWithIcon
                                        title="Transactions"
                                        iconPath="/ic_transactions_outline.svg"
                                    />
                                    {/* / Transactions */}
                                </StyledTableHeadCell>
                                <StyledTableHeadCell align="left">
                                    <TabelHeadWithIcon
                                        title="Products"
                                        iconPath="/products.svg"
                                    />
                                    {/* {t("common.Products")} */}
                                </StyledTableHeadCell>
                                <StyledTableHeadCell align="left">
                                    <TabelHeadWithIcon
                                        title="New Customers"
                                        iconPath="/ic_new.svg"
                                    />
                                    {/* {t("common.Products")} */}
                                </StyledTableHeadCell>
                                <StyledTableHeadCell align="left">
                                    <TabelHeadWithIcon
                                        title="Customers"
                                        iconPath="/ic_customers_many.svg"
                                    />
                                    {/* {t("common.Products")} */}
                                </StyledTableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!fakeDataArray?.length &&
                                Array.from(Array(7).keys()).map((i) => (
                                    <TableRow
                                        key={i}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                            colSpan={6}
                                        >
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="left"
                                            colSpan={4}
                                        >
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            -
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            -
                                        </StyledTableCell>
                                    </TableRow>
                                ))}

                            {fakeDataArray?.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <StyledTableCell
                                        component="th"
                                        scope="row"
                                        colSpan={6}
                                    >
                                        {row.data}
                                    </StyledTableCell>
                                    <StyledTableCell align="left" colSpan={4}>
                                        {numberWithCommas(row.total_sales)}{" "}
                                        <CurrencyIcon />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {numberWithCommas(row.profitability)}{" "}
                                        <CurrencyIcon />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.transactions}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.products}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.new_customers}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.customers}
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Stack>
    );
};

export { HistoryData };
