import {
    ResponsiveContainer,
    BarChart,
    XAxis,
    Bar,
    YAxis,
    Tooltip,
} from "recharts";
import { useTranslation } from "react-i18next";
import { CurrencyIcon } from "../../Currency/CurrencyIcon";

const CustomTick = ({ x, y, payload, ...props }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const textAnchor = isArabic ? "start" : "end";
    const xOffset = isArabic ? 80 : 0; // Adjust this value as needed

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={xOffset}
                y={0}
                dy={16}
                textAnchor={textAnchor}
                fill="#666"
                {...props}
            >
                {payload.value} <CurrencyIcon />
            </text>
        </g>
    );
};

const CustomizedXAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
        <text
            x={x}
            y={y}
            dy={10}
            textAnchor="middle"
            fill="#202020"
            fontSize={14}
            {...props}
        >
            {payload.value}
        </text>
    );
};

function getMaxHeight(data) {
    if (!data) return null;
    // Ensure that the data array is not empty
    if (data?.length === 0) {
        return null; // or handle as needed
    }

    // Initialize max height with the first value
    let maxHeight = parseFloat(data[0].uv);

    // Iterate through the array to find the maximum height
    for (let i = 1; i < data.length; i++) {
        const height = parseFloat(data[i].uv);
        if (!isNaN(height) && height > maxHeight) {
            maxHeight = height;
        }
    }

    return maxHeight;
}

const SimpleBar = ({
    data,
    container = { width: "100%", height: 300 },
    barSize = 20,
    margin,
    xTickProps = {},
    yTickProps = {},
    xAxisProps = {},
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const maxBarHeight = getMaxHeight(data);

    const roundedMaxValue = Math.ceil(maxBarHeight / 1000) * 1000;
    // Generate tick values in increments of 1000
    const tickValues = Array.from(
        { length: roundedMaxValue / 1000 + 1 },
        (_, index) => index * 1000
    );

    return (
        <ResponsiveContainer width={container.width} height={container.height}>
            <BarChart
                data={data}
                margin={
                    margin || {
                        top: 5,
                        right: isArabic ? 70 : 0,
                        left: isArabic ? 0 : 70,
                        bottom: 5,
                    }
                }
            >
                <XAxis
                    dataKey="name"
                    type="category"
                    tickLine={false}
                    axisLine={{ stroke: "#EEEFF4" }}
                    reversed={isArabic}
                    tick={<CustomizedXAxisTick {...xTickProps} />}
                    {...xAxisProps}
                />
                <YAxis
                    width={100}
                    dataKey="uv"
                    tickLine={false}
                    axisLine={false}
                    unit={t("common.currency")}
                    orientation={isArabic ? "right" : "left"}
                    tick={(props) => <CustomTick {...props} {...yTickProps} />}
                    domain={[0, roundedMaxValue]}
                    tickCount={tickValues.length}
                    ticks={tickValues}
                />

                <Tooltip
                    cursor={false} // Disable the cursor highlight
                    contentStyle={{
                        background: "#fff",
                        border: "1px solid #ccc",
                    }} // Customize the tooltip style
                    formatter={(value, name, props) => [
                        `${value} ${t("common.currency")}`,
                        name,
                    ]} // Customize the tooltip content
                    wrapperStyle={{ outline: "none" }}
                />

                <Bar
                    dataKey="uv"
                    fill="#9FE3B9"
                    background={{ fill: "#F2F3F7" }}
                    barSize={barSize}
                    name={t("sales.sales")}
                />
            </BarChart>
        </ResponsiveContainer>
    );
};

export { SimpleBar };
