export const CurrencyIcon = () => {
    return (
        <span style={{ position: "relative", top: 2 }}>
            {" "}
            <img
                width="18px"
                src="/riyal-symbol.svg"
                alt="sideNavBarIcon"
                style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                }}
            />
        </span>
    );
};
