import {
    Box,
    Grid,
    MenuItem,
    Select,
    Stack,
    Icon,
    Typography,
    LinearProgress,
    Divider,
    linearProgressClasses,
    CircularProgress,
    Tooltip,
    Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
    ButtonSelect,
    CustomSelect,
    DoughnutChart,
    GridCard,
    GridCardFlatLabels,
    RangePicker,
} from "../../../../common/components";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { InfoCard } from "../Components/InfoCard";
import { services } from "../../services";
import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { constants } from "../../../../config/constants";
import { TopCustomersTable } from "./TopCustomers";
import { getToken, getUserStorage } from "../../../../common/utils";
import { numberWithCommas } from "../../../../common/utils/numberWithCommas";
import { CustomersCountsData } from "../Components/CustomersCountsData";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { VictoryPie } from "victory";
import { ExportButton } from "../../../../common/components/Export/Export";
import { CurrencyIcon } from "../../../../common/components/Currency/CurrencyIcon";

const GenderIcon = ({ iconPath }) => {
    return (
        <Box
            width="48px"
            height="48px"
            backgroundColor="#F1F5F9"
            borderRadius="6px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            // padding={2.3}
        >
            <Icon
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <img src={iconPath} alt="infoCardIcon" />
            </Icon>
        </Box>
    );
};

const CustomersInsights = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const navigate = useNavigate();

    const user = getUserStorage();
    const isOwner = user.role === 1;
    const userBranches =
        isOwner && !user.manager_view ? user?.merchant?.branches : user?.branch;
    const allBranches = userBranches.map((branch) => ({
        id: branch.branch_id,
        label: branch.name,
    }));
    const isGroupOwner = user.role === 9;

    const userGroups = user?.group;
    const allGroups =
        userGroups?.map((group) => ({
            id: group.merchant_id,
            label: group.name,
        })) || [];
    const allGroupsOption = { id: "all", label: t("common.all_groups") };
    const [selectedGroup, setSelectedGroup] = useState(allGroupsOption.id);

    const isManagerView = user.manager_view;
    const allBranchesOption = { id: "all", label: t("common.all_branches") };
    const [selectedBranch, setSelectedBranch] = useState(
        isManagerView ? allBranches[0].id : allBranchesOption.id
    );
    const [customerType, setCustomerType] = useState("Top Customers");
    const [period, setPeriod] = useState({
        startDate: null,
        endDate: format(new Date(), "y-M-d"),
    });
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
    const [overviewPDF, setOverviewPDF] = useState({});

    const [topCustomers, setTopCustomers] = useState(null);
    const [customerSegments, setCustomerSegments] = useState(null);
    const [customerInsights, setCustomerInsights] = useState(null);
    const [customersCount, setCustomersCount] = useState(null);
    const [genderData, setGenderData] = useState(null);
    const [genderInsights, setGenderInsights] = useState(null);

    const emptyChart = [
        {
            name: t("common.empty"),
            value: 100,
            fill: "#E3E3E3",
            outerRadius: 200,
        },
    ];

    function getTotalCount(arr, key = "total_sales") {
        let count = 0;
        arr.forEach((i) => (count = count + +i[key]));

        return count.toFixed(2);
    }

    const { mutate: getCustomerInsights, isLoading } = useMutation({
        mutationFn: services.getCustomerInsights,
        onSuccess: (res) => {
            if (res.data.response && !res.data.error) {
                const response = res.data.response;

                const segments = {};
                response.customer_segments
                    ?.filter((s) => s.segmentation)
                    .forEach((segment) => {
                        const segmentKey = segment.segmentation
                            .replace(/\s+/g, "_")
                            .toLowerCase();
                        segments[segmentKey] = {
                            label: segment.segmentation,
                            count: segment.count,
                        };
                    });

                const insights = {};
                response.customer_insights?.forEach((insight) => {
                    const insightKey = insight.average_measure
                        .replace(/\s+/g, "_")
                        .toLowerCase();
                    insights[insightKey] = {
                        label: insight.average_measure,
                        average_value: insight.average_value,
                    };
                });

                const genderDataTotal = getTotalCount(response.gender_sales);
                const genderPersonaDataTotal = getTotalCount(
                    response.gender_counts,
                    "count"
                );
                const customersCountTotal = getTotalCount(
                    response.new_vs_retained,
                    "customer_count"
                );
                const customers_count = response.new_vs_retained
                    .map((customer, index) => {
                        const outerRadius =
                            +(
                                ((+customer.customer_count /
                                    customersCountTotal) *
                                    1000) /
                                10
                            ).toFixed(0) + 120;

                        return {
                            name: t(
                                `charts.customer_counts.${customer.customer_type}`
                            ),
                            value: +(
                                ((+customer.customer_count /
                                    customersCountTotal) *
                                    1000) /
                                10
                            ).toFixed(2),
                            sales: numberWithCommas(customer.customer_count),
                            fill: constants.pieColors[index],
                            innerRadius: (index + 1) * 10,
                            outerRadius: outerRadius,
                        };
                    })
                    .filter((c) => c.value);

                const gender_data = refineGenderData(
                    response.gender_sales.map((gender) => ({
                        name: gender.gender_,
                        value:
                            ((+gender.total_sales / genderDataTotal).toFixed(
                                4
                            ) *
                                1000) /
                            10,
                        total_sales: +gender.total_sales,
                    }))
                );

                // (gender, index) => ()
                const gender_insights = response.gender_counts
                    //   .sort((a, b) => a.count - b.count)
                    .map((gender, index) => {
                        const outerRadius =
                            +(
                                ((+gender.count / genderPersonaDataTotal) *
                                    1000) /
                                10
                            ).toFixed(0) + 120;

                        return {
                            name: t(`common.${gender.gender.toLowerCase()}`),
                            value: +(
                                ((+gender.count / genderPersonaDataTotal) *
                                    1000) /
                                10
                            ).toFixed(2),
                            sales: numberWithCommas(gender.count),
                            fill: constants.pieColors[index],
                            innerRadius: (index + 1) * 10,
                            outerRadius: outerRadius,
                        };
                    });

                const top_customers = res.data.response?.top_customers?.splice(
                    0,
                    4
                );

                setTopCustomers(top_customers);
                setCustomerSegments(segments);
                setGenderInsights(gender_insights);
                setCustomersCount(customers_count);
                setGenderData(gender_data);
                setCustomerInsights(insights);

                setOverviewPDF({
                    top_customers,
                    segments,
                    gender_insights,
                    customers_count,
                    gender_data,
                    insights,
                    period,
                });
            }

            if (res.data.error) {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    function refineGenderData(arr) {
        const refinedObj = {
            Male: { name: "Male", value: 0, total_sales: 0 },
            Female: { name: "Female", value: 0, total_sales: 0 },
            Undefined: { name: "Undefined", value: 0, total_sales: 0 },
        };

        arr.forEach((g) => {
            if (g.name === "Male") refinedObj.Male = g;
            else if (g.name === "Female") refinedObj.Female = g;
            else refinedObj.Undefined = g;
        });

        return Object.values(refinedObj);
    }

    const handleDatesChange = ({ startDate, endDate }) => {
        if (startDate !== "1970-1-1") {
            setPeriod({ startDate, endDate });

            getCustomerInsights({
                from_date: startDate,
                till_date: endDate,
                customer_type: customerType,
                [`${isGroupOwner ? "merchant_filter" : "branch_id"}`]:
                    isGroupOwner ? selectedGroup : selectedBranch,
            });
        }
    };

    const handleBranchChange = (e) => {
        const newSelectedBranchValue = e.target.value;
        setSelectedBranch(newSelectedBranchValue);
        getCustomerInsights({
            from_date: period.startDate,
            till_date: period.endDate,
            customer_type: customerType,
            branch_id: newSelectedBranchValue,
        });
    };

    const handleGroupChange = (e) => {
        let newSelectedGroupValue = e.target.value;
        const lastElement =
            newSelectedGroupValue[newSelectedGroupValue.length - 1];
        if (lastElement === "all" || !newSelectedGroupValue.length)
            newSelectedGroupValue = "all";
        else
            newSelectedGroupValue = newSelectedGroupValue.filter(
                (i) => i !== "all"
            );
        setSelectedGroup(newSelectedGroupValue);

        getCustomerInsights({
            from_date: period.startDate,
            till_date: period.endDate,
            customer_type: customerType,
            merchant_filter: newSelectedGroupValue,
        });
    };

    const handleCustomerTypeChange = (value) => {
        setCustomerType(value);
        getCustomerInsights({
            from_date: period.startDate,
            till_date: period.endDate,
            customer_type: value,
        });
    };

    const getGenderName = (genderName) => {
        if (genderName === "Male") return t("common.male");
        if (genderName === "Female") return t("common.female");
        return t("common.other");
    };

    useEffect(() => {
        if (period.startDate) {
            getCustomerInsights({
                from_date: period.startDate,
                till_date: period.endDate,
                customer_type: customerType,
                [`${isGroupOwner ? "merchant_filter" : "branch_id"}`]:
                    isGroupOwner ? selectedGroup : selectedBranch,
            });
        }
    }, []);

    useEffect(() => {
        window.pendo.pageLoad({
            name: "Customers Insights Page",
        });
    }, []);

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <Grid container flexWrap="nowrap">
                <Grid
                    item
                    xs={6}
                    marginRight={isArabic ? 0 : 8}
                    marginLeft={isArabic ? 8 : 0}
                >
                    <Stack spacing={0}>
                        <Typography fontWeight="bold" fontSize={30}>
                            {t("customer.insights")}
                        </Typography>
                        {/* <Typography variant="caption" color="#8a8b8b">12345 customers</Typography> */}
                    </Stack>
                </Grid>
            </Grid>

            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box display="flex" alignItems="center" gap={3}>
                    <RangePicker
                        onChange={(value) => handleDatesChange(value)}
                        isLoading={isLoading}
                    />
                </Box>

                <Box display="flex" alignItems="center" gap={3}>
                    {!isManagerView && !isGroupOwner && (
                        <ButtonSelect
                            value={selectedBranch}
                            onChange={handleBranchChange}
                            options={[allBranchesOption, ...allBranches]}
                            isLoading={isLoading}
                        />
                    )}

                    {isGroupOwner && (
                        <ButtonSelect
                            value={selectedGroup}
                            onChange={handleGroupChange}
                            options={[allGroupsOption, ...allGroups]}
                            isLoading={isLoading}
                            isMulti
                        />
                    )}

                    <ExportButton
                        overviewPDF={overviewPDF}
                        pageName="customerInsightsPDF"
                        isLoading={isLoading}
                    />
                </Box>
            </Box>

            <Grid container spacing={0} rowSpacing={4}>
                <Grid item xs={6} md={2.4}>
                    <InfoCard
                        containerStyles={{
                            height: 156,
                            midWidth: 240,
                            marginRight: isArabic ? 0 : 4,
                            marginLeft: isArabic ? 4 : 0,
                        }}
                        label={t("customer.top_customers")}
                        value={customerSegments?.top_customers?.count || "0"}
                        iconPath="/ic_Active_cust.svg"
                        onClick={() =>
                            navigate(
                                `/customers/data?segment=Top Customers&start=${period.startDate}&end=${period.endDate}`
                            )
                        }
                        tooltipLabel={t("tooltips.top_customers")}
                    />
                </Grid>

                <Grid item xs={6} md={2.4}>
                    <InfoCard
                        containerStyles={{
                            height: 156,
                            midWidth: 240,
                            marginRight: isArabic ? 0 : 4,
                            marginLeft: isArabic ? 4 : 0,
                        }}
                        label={t("customer.one_time_customers")}
                        value={
                            customerSegments?.one_time_customers?.count || "0"
                        }
                        iconPath="/ic_customers_black.svg"
                        onClick={() =>
                            navigate(
                                `/customers/data?segment=One Time Customers&start=${period.startDate}&end=${period.endDate}`
                            )
                        }
                        tooltipLabel={t("tooltips.one_time_customes")}
                    />
                </Grid>

                <Grid item xs={6} md={2.4}>
                    <InfoCard
                        containerStyles={{
                            height: 156,
                            midWidth: 240,
                            marginRight: isArabic ? 0 : 4,
                            marginLeft: isArabic ? 4 : 0,
                        }}
                        label={t("customer.new_customers")}
                        value={customerSegments?.new_customers?.count || "0"}
                        iconPath="/ic_new.svg"
                        onClick={() =>
                            navigate(
                                `/customers/data?segment=New Customers&start=${period.startDate}&end=${period.endDate}`
                            )
                        }
                        tooltipLabel={t("tooltips.new_customers")}
                    />
                </Grid>
                <Grid item xs={6} md={2.4}>
                    <InfoCard
                        containerStyles={{
                            height: 156,
                            midWidth: 240,
                            marginRight: isArabic ? 0 : 4,
                            marginLeft: isArabic ? 4 : 0,
                        }}
                        label={t("customer.customers_at_risk")}
                        value={
                            customerSegments?.customers_at_risk?.count || "0"
                        }
                        iconPath="/ic_Risk.svg"
                        onClick={() =>
                            navigate(
                                `/customers/data?segment=Customers at Risk&start=${period.startDate}&end=${period.endDate}`
                            )
                        }
                        tooltipLabel={t("tooltips.risk_customers")}
                    />
                </Grid>

                <Grid item xs={6} md={2.4}>
                    <InfoCard
                        containerStyles={{ height: 156, midWidth: 240 }}
                        label={t("customer.lost_customers")}
                        value={customerSegments?.lost_customers?.count || "0"}
                        iconPath="/ic_Lost.svg"
                        onClick={() =>
                            navigate(
                                `/customers/data?segment=Lost Customers&start=${period.startDate}&end=${period.endDate}`
                            )
                        }
                        tooltipLabel={t("tooltips.lost_customers")}
                    />
                </Grid>
            </Grid>

            <Stack
                width="100%"
                backgroundColor="white"
                boxShadow="0 1px 3px hsla(220,6%,43%,0.2)"
                borderRadius={2}
                height={458}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    padding={6}
                    height={74}
                >
                    <Typography fontWeight="bold" fontSize={20} color="#202020">
                        {t("customer.customer_persona")}
                    </Typography>

                    <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={customerType}
                        onChange={(e) =>
                            handleCustomerTypeChange(e.target.value)
                        }
                        input={<CustomSelect />}
                    >
                        <MenuItem value="Top Customers">
                            {t("customer.top_customers")}
                        </MenuItem>
                        <MenuItem value="One Time Customers">
                            {t("customer.one_time_customers")}
                        </MenuItem>
                        <MenuItem value="New Customers">
                            {t("customer.new_customers")}
                        </MenuItem>
                        <MenuItem value="Customers at Risk">
                            {t("customer.customers_at_risk")}
                        </MenuItem>
                        <MenuItem value="Lost Customers">
                            {t("customer.lost_customers")}
                        </MenuItem>
                    </Select>
                </Box>

                <Divider />
                <Box padding={6}>
                    <Grid container wrap="nowrap">
                        <Grid item xs={4} marginRight={6}>
                            <Stack spacing={6}>
                                <InfoCard
                                    containerStyles={{
                                        height: 156,
                                        border: "1px solid #E5E6E9",
                                        boxShadow: "none",
                                    }}
                                    label={t("customer.average_basket_value")}
                                    value={
                                        <>
                                            {numberWithCommas(
                                                customerInsights
                                                    ?.average_basket_value
                                                    ?.average_value
                                            ) || 0}
                                            <CurrencyIcon />{" "}
                                        </>
                                    }
                                    tooltipLabel={t(
                                        "tooltips.customer_avg_basket_value"
                                    )}
                                />

                                <InfoCard
                                    containerStyles={{
                                        height: 156,
                                        border: "1px solid #E5E6E9",
                                        boxShadow: "none",
                                    }}
                                    label={t("customer.average_transactions")}
                                    value={`${
                                        customerInsights?.average_transactions
                                            ?.average_value || 0
                                    }`}
                                    tooltipLabel={t(
                                        "tooltips.customer_avg_transactions"
                                    )}
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={4} marginRight={6}>
                            <Stack spacing={6}>
                                <InfoCard
                                    containerStyles={{
                                        height: 156,
                                        border: "1px solid #E5E6E9",
                                        boxShadow: "none",
                                    }}
                                    label={t("overview.avg_basket_size")}
                                    value={`${
                                        customerInsights?.average_basket_size
                                            ?.average_value || 0
                                    } ${t("common.product_s")}`}
                                    tooltipLabel={t(
                                        "tooltips.customer_avg_basket_size"
                                    )}
                                />

                                <InfoCard
                                    containerStyles={{
                                        height: 156,
                                        border: "1px solid #E5E6E9",
                                        boxShadow: "none",
                                    }}
                                    label={t("overview.total_sales")}
                                    value={
                                        <>
                                            {numberWithCommas(
                                                customerInsights?.total_sales
                                                    ?.average_value
                                            ) || 0}
                                            <CurrencyIcon />{" "}
                                        </>
                                    }
                                    tooltipLabel={t(
                                        "tooltips.customer_total_sales"
                                    )}
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={4} marginRight={4}>
                            {genderInsights && (
                                <Stack
                                    border="1px solid #E5E6E9"
                                    borderRadius={5}
                                    width="100%"
                                    height="100%"
                                    gap={3}
                                    // paddingX={8}
                                    paddingTop={6}
                                    paddingBottom={0}
                                >
                                    <Box
                                        display="flex"
                                        gap={3}
                                        alignItems="baseline"
                                        px={8}
                                    >
                                        <Typography
                                            fontSize="18px"
                                            color="#999999"
                                        >
                                            {t("customer.demographic_info")}
                                        </Typography>

                                        <Tooltip
                                            title={t(
                                                "tooltips.customer_demographic_info"
                                            )}
                                        >
                                            <HelpOutlineIcon
                                                sx={{ fontSize: 16 }}
                                            />
                                        </Tooltip>
                                    </Box>

                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        dir="ltr"
                                    >
                                        <Box
                                            width="50%"
                                            maxWidth={200}
                                            height="100%"
                                        >
                                            <svg
                                                viewBox="0 0 400 300"
                                                style={{
                                                    // width: "100%",
                                                    // height: "100%",
                                                    padding: "0",
                                                    marginTop: "10px",
                                                    overflow: "visible",
                                                }}
                                            >
                                                <VictoryPie
                                                    standalone={false}
                                                    x="name"
                                                    y="value"
                                                    data={
                                                        genderInsights?.length
                                                            ? genderInsights
                                                            : emptyChart
                                                    }
                                                    colorScale={
                                                        genderInsights?.length
                                                            ? constants.pieColors
                                                            : ["#E3E3E3"]
                                                    }
                                                    radius={47}
                                                    innerRadius={52}
                                                    labelRadius={({ datum }) =>
                                                        datum.outerRadius + 80
                                                    }
                                                    labels={({ datum }) => ""}
                                                    style={{
                                                        labels: {
                                                            fontSize: 26,
                                                        },
                                                    }}
                                                />

                                                <VictoryPie
                                                    standalone={false}
                                                    x="name"
                                                    y="value"
                                                    data={
                                                        genderInsights?.length
                                                            ? genderInsights
                                                            : emptyChart
                                                    }
                                                    colorScale={
                                                        genderInsights?.length
                                                            ? constants.pieColors
                                                            : ["#E3E3E3"]
                                                    }
                                                    radius={({ datum }) =>
                                                        datum.outerRadius
                                                    }
                                                    labelRadius={80}
                                                    innerRadius={55}
                                                    // labelComponent={
                                                    //     <VictoryTooltip
                                                    //         renderInPortal
                                                    //         style={{
                                                    //             fill: "black",
                                                    //             fontSize: "22px",
                                                    //         }}
                                                    //         pointerLength={0}
                                                    //         flyoutPadding={10}
                                                    //         flyoutStyle={{
                                                    //             fill: "white",
                                                    //             strokeWidth: 1,
                                                    //         }}
                                                    //     />
                                                    // }
                                                    style={{
                                                        labels: {
                                                            fontSize: 24,
                                                            color: "white",
                                                            fill: "white",
                                                        },
                                                    }}
                                                    labels={
                                                        ({ datum }) =>
                                                            `${datum.value}%`
                                                        // `${((datum.value / total) * 100).toFixed(
                                                        //     0
                                                        // )}%`
                                                    }
                                                    // labels={({ datum }) =>
                                                    //     data?.length > 5
                                                    //         ? ` `
                                                    //         : isArabic
                                                    //         ? `%${((datum.value / total) * 100).toFixed(
                                                    //               0
                                                    //           )}`
                                                    //         : `${((datum.value / total) * 100).toFixed(
                                                    //               0
                                                    //           )}%`
                                                    // }
                                                />
                                            </svg>
                                        </Box>
                                    </Box>

                                    <Box
                                        position="relative"
                                        top="45px"
                                        display="flex"
                                        justifyContent="center"
                                        width="100%"
                                    >
                                        <GridCardFlatLabels
                                            data={
                                                genderInsights?.length
                                                    ? genderInsights
                                                    : emptyChart
                                            }
                                            isFlat
                                            isCenter
                                            withPercentage={false}
                                        />
                                    </Box>
                                </Stack>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Stack>

            <Grid container spacing={0} flexWrap="nowrap">
                <GridCard gutter padding={0} height={360}>
                    <TopCustomersTable data={topCustomers} />
                </GridCard>

                <GridCard padding={0}>
                    <Stack>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            padding={6}
                            height={74}
                        >
                            <Typography fontSize={20} fontWeight="bold">
                                {t("customer.sales_value")} /{" "}
                                {t("common.gender")}
                            </Typography>

                            <Typography fontSize={18} color="#71737B">
                                {t("overview.total_sales")}{" "}
                                {getTotalCount(genderData || [])}{" "}
                                <CurrencyIcon />
                            </Typography>
                        </Box>

                        <Divider />

                        <Box padding={6}>
                            <Stack spacing={8}>
                                {genderData?.map((gender) => (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        position="relative"
                                        gap={4}
                                    >
                                        <Box>
                                            {gender.name === "Male" && (
                                                <GenderIcon iconPath="/ic_male.svg" />
                                            )}
                                            {gender.name === "Female" && (
                                                <GenderIcon iconPath="/ic_female.svg" />
                                            )}
                                            {(!gender.name ||
                                                gender.name === "Other") && (
                                                <GenderIcon iconPath="/ic_Other.svg" />
                                            )}
                                        </Box>

                                        <Stack width="100%" spacing={3}>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <Box
                                                    display="flex"
                                                    alignItems="baseline"
                                                    gap={1}
                                                >
                                                    <Typography
                                                        fontSize={14}
                                                        color="#999999"
                                                    >
                                                        {getGenderName(
                                                            gender.name
                                                        )}
                                                    </Typography>

                                                    <Typography
                                                        fontSize={14}
                                                        color="#334155"
                                                    >
                                                        <CurrencyIcon />{" "}
                                                        {gender.total_sales}
                                                    </Typography>
                                                </Box>

                                                <Typography
                                                    fontSize={16}
                                                    fontWeight="bold"
                                                    color="#334155"
                                                >
                                                    {isArabic
                                                        ? `%${gender.value.toFixed(
                                                              2
                                                          )}`
                                                        : `${gender.value.toFixed(
                                                              2
                                                          )}%`}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ width: "100%" }}>
                                                <LinearProgress
                                                    sx={{
                                                        backgroundColor:
                                                            "#F1F5F9",
                                                        [`& .${linearProgressClasses.bar}`]:
                                                            {
                                                                borderRadius: 5,
                                                                backgroundColor:
                                                                    !!gender.name
                                                                        ? gender.name ===
                                                                          "Male"
                                                                            ? "lightBlue"
                                                                            : "lightGreen"
                                                                        : "dark",
                                                            },
                                                    }}
                                                    variant="determinate"
                                                    value={gender.value}
                                                />
                                            </Box>
                                        </Stack>
                                    </Box>
                                ))}
                            </Stack>
                        </Box>
                    </Stack>
                </GridCard>
            </Grid>

            <Grid container spacing={0} flexWrap="nowrap">
                <GridCard gutter padding={0}>
                    {customersCount && (
                        <CustomersCountsData data={customersCount} />
                    )}
                </GridCard>

                <Grid item xs={6} />
            </Grid>
        </Stack>
    );
};

export { CustomersInsights };
