import { useEffect, useMemo, useState } from "react";
import { Box, Grid, MenuItem, Select, Switch, Typography } from "@mui/material";
import {
    ButtonSelect,
    CustomSelectOutline,
    GridCard,
    RangePicker,
} from "../../../../../common/components";
import { services } from "../../../services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { format, isToday, subMonths, subYears } from "date-fns";
import { useTranslation } from "react-i18next";

import { getUserStorage } from "../../../../../common/utils";
import { toast } from "react-toastify";
// import { ExportButton } from "../../../../../common/components/Export/Export";
import { PLTable } from "../charts/PLTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { differenceInDays, subDays } from "date-fns";
import { AuthServices } from "../../../../../Auth/services";

function getPreviousPeriod(startDate, endDate) {
    const daysDifference = differenceInDays(endDate, startDate);

    // General case for custom period
    const previousStartDate = subDays(startDate, daysDifference);
    const previousEndDate = subDays(endDate, daysDifference + 1);
    return { previousStartDate, previousEndDate };
}

const PL = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const { data: firstDateData } = useQuery({
        queryKey: ["firstSalesDate"],
        queryFn: AuthServices.getMerchantFirstDate,
        refetchOnWindowFocus: false,
    });

    const [period, setPeriod] = useState({
        startDate: null,
        endDate: format(new Date(), "y-M-d"),
    });

    const user = getUserStorage();
    const isOwner = user.role === 1;
    const userBranches =
        isOwner && !user.manager_view ? user?.merchant?.branches : user?.branch;
    const allBranches = userBranches.map((branch) => ({
        id: branch.branch_id,
        label: branch.name,
    }));
    const isGroupOwner = user.role === 9;

    const userGroups = user?.group;
    const allGroups =
        userGroups?.map((group) => ({
            id: group.merchant_id,
            label: group.name,
        })) || [];
    const allGroupsOption = { id: "all", label: t("common.all_groups") };
    const [selectedGroup, setSelectedGroup] = useState(allGroupsOption.id);

    const allChannelsOption = { id: "all", label: t("common.all_channels") };
    // const allBrandsOption = { id: "all", label: "All Brands" };
    const isManagerView = user.manager_view;
    const allBranchesOption = { id: "all", label: t("common.all_branches") };
    const [selectedBranch] = useState(
        isManagerView ? allBranches[0].id : allBranchesOption.id
    );
    const [selectedChannel] = useState(allChannelsOption.id);

    const [compareWithPrev, setCompareWithPrev] = useState(false);

    const { mutate: getSalesAggregators, isLoading } = useMutation({
        mutationFn: services.getSalesAggregators,
        onSuccess: (res) => {
            if (res.data.response && !res.data.error) {
                const response = res.data.response;
            }

            if (res.data.error) {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    // const { data } = useQuery({
    //     queryKey: ["allProducts"],
    //     queryFn: services.getMerchantChannels,
    // });

    // const getChannelsNames = useCallback(() => {
    //     return data?.data?.response.map((app) => ({
    //         id: app.app_id,
    //         label: app.name,
    //     }));
    // }, [data?.data?.response]);

    // const allChannels = getChannelsNames() || [];

    // useEffect(() => {
    //     if (period.startDate) {
    //         const prevDate = getPreviousPeriod(
    //             new Date(period.startDate),
    //             new Date(period.endDate)
    //         );

    //         // getSalesAggregators({
    //         //     from_date: period.startDate,
    //         //     till_date: period.endDate,
    //         //     ...(compareWithPrev
    //         //         ? {
    //         //               prev_from_date: format(
    //         //                   new Date(prevDate.previousStartDate),
    //         //                   "y-M-d"
    //         //               ),
    //         //               prev_till_date: format(
    //         //                   new Date(prevDate.previousEndDate),
    //         //                   "y-M-d"
    //         //               ),
    //         //           }
    //         //         : {}),
    //         //     channel_id: "all",
    //         //     branch_id: "all",
    //         // });
    //     }
    // }, [compareWithPrev]);

    const handleDatesChange = ({ startDate, endDate }) => {
        if (startDate !== "1970-1-1") {
            setPeriod({ startDate, endDate });
            const prevDate = getPreviousPeriod(
                new Date(startDate),
                new Date(endDate)
            );

            // getSalesAggregators({
            //     from_date: startDate,
            //     till_date: endDate,
            //     ...(compareWithPrev
            //         ? {
            //               prev_from_date: format(
            //                   new Date(prevDate.previousStartDate),
            //                   "y-M-d"
            //               ),
            //               prev_till_date: format(
            //                   new Date(prevDate.previousEndDate),
            //                   "y-M-d"
            //               ),
            //           }
            //         : {}),
            //     branch_id: selectedBranch,
            //     channel_id: selectedChannel,
            // });
        }
    };

    const handleTodayDatesChange = (value) => {
        let prevStartDate = period.startDate;
        let prevEndDate = period.endDate;

        if (value === "yesterday") {
            prevStartDate = subDays(new Date(), 1);
            prevEndDate = subDays(new Date(), 1);
        }
        if (value === "same_day_prev_week") {
            prevStartDate = subDays(new Date(), 7);
            prevEndDate = subDays(new Date(), 7);
        }
        if (value === "same_day_prev_month") {
            prevStartDate = subMonths(new Date(), 1);
            prevEndDate = subMonths(new Date(), 1);
        }
        if (value === "same_day_prev_3_month") {
            prevStartDate = subMonths(new Date(), 3);
            prevEndDate = subMonths(new Date(), 3);
        }
        if (value === "same_day_prev_year") {
            prevStartDate = subYears(new Date(), 1);
            prevEndDate = subYears(new Date(), 1);
        }

        if (period.startDate !== "1970-1-1") {
            getSalesAggregators({
                from_date: period.startDate,
                till_date: period.endDate,

                prev_from_date: format(new Date(prevStartDate), "y-M-d"),
                prev_till_date: format(new Date(prevEndDate), "y-M-d"),
                branch_id: selectedBranch,
                channel_id: selectedChannel,
            });
        }
    };

    const handleGroupChange = (e) => {
        const newSelectedGroupValue = e.target.value;
        setSelectedGroup(newSelectedGroupValue);

        getSalesAggregators({
            from_date: period.startDate,
            till_date: period.endDate,
            ...(compareWithPrev
                ? {
                      prev_from_date: period.startDate,
                      prev_till_date: period.endDate,
                  }
                : {}),
            merchant_filter: newSelectedGroupValue,
        });
    };

    // const handleBranchChange = (e) => {
    //     const newSelectedBranchValue = e.target.value;
    //     setSelectedBranch(newSelectedBranchValue);
    // getSalesAggregators({
    //     from_date: period.startDate,
    //     till_date: period.endDate,
    //     ...(compareWithPrev
    //         ? {
    //               prev_from_date: period.startDate,
    //               prev_till_date: period.endDate,
    //           }
    //         : {}),
    //     branch_id: newSelectedBranchValue,
    //     channel_id: selectedChannel,
    // });
    // };

    // const handleChannelChange = (e) => {
    //     const newSelectedChannelValue = e.target.value;
    //     setSelectedChannel(newSelectedChannelValue);
    //     getSalesAggregators({
    //         from_date: period.startDate,
    //         till_date: period.endDate,
    //         ...(compareWithPrev
    //             ? {
    //                   prev_from_date: period.startDate,
    //                   prev_till_date: period.endDate,
    //               }
    //             : {}),
    //         channel_id: newSelectedChannelValue,
    //         branch_id: selectedBranch,
    //     });
    // };

    // const handleBrandChange = (e) => {
    //     const newSelectedBrandValue = e.target.value;
    //     setSelectedBrand(newSelectedBrandValue);
    // };

    useEffect(() => {
        window.pendo.pageLoad({
            name: "Sales Aggregators Page",
        });
    }, []);

    const isTodayFilterApplied = useMemo(
        () =>
            isToday(new Date(period.startDate)) &&
            isToday(new Date(period.endDate)),
        [period]
    );

    const isAllTimeFilterApplied = useMemo(() => {
        if (!firstDateData?.data?.response?.first_date) return false;
        if (
            format(new Date(period.startDate), "y-M-d") ===
                format(
                    new Date(firstDateData?.data?.response?.first_date),
                    "y-M-d"
                ) &&
            isToday(new Date(period.endDate))
        ) {
            return true;
        }
    }, [firstDateData, period]);

    useEffect(() => {
        if (compareWithPrev && isAllTimeFilterApplied)
            setCompareWithPrev(false);
        if (!compareWithPrev && isTodayFilterApplied) setCompareWithPrev(true);
    }, [isTodayFilterApplied, compareWithPrev, isAllTimeFilterApplied]);

    return (
        <>
            <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" gap={3} alignItems="center">
                    <RangePicker
                        onChange={(values) => handleDatesChange(values)}
                        isLoading={isLoading}
                    />

                    {isTodayFilterApplied ? (
                        <>
                            <Typography variant="body2">
                                {t("common.compared_to")}
                            </Typography>

                            <Select
                                defaultValue="yesterday"
                                onChange={(e) =>
                                    handleTodayDatesChange(e.target.value)
                                }
                                input={<CustomSelectOutline />}
                                IconComponent={ExpandMoreIcon}
                                disabled={isLoading}
                            >
                                <MenuItem value="yesterday">
                                    {" "}
                                    {t("likeForLike.yesterday")}
                                </MenuItem>
                                <MenuItem value="same_day_prev_week">
                                    {t("likeForLike.same_day_last_week")}
                                </MenuItem>
                                <MenuItem value="same_day_prev_month">
                                    {t("likeForLike.same_day_prev_month")}
                                </MenuItem>
                                <MenuItem value="same_day_prev_3_month">
                                    {t("likeForLike.same_day_last_qur")}
                                </MenuItem>
                                <MenuItem value="same_day_prev_year">
                                    {t("likeForLike.same_day_last_year")}
                                </MenuItem>
                            </Select>
                        </>
                    ) : (
                        !isAllTimeFilterApplied && (
                            <Box display="flex" alignItems="center">
                                <Typography>
                                    {t("sales.compare_to_prev")}
                                </Typography>
                                <Switch
                                    checked={compareWithPrev}
                                    onChange={() =>
                                        setCompareWithPrev(!compareWithPrev)
                                    }
                                />
                            </Box>
                        )
                    )}
                </Box>

                <Box display="flex" alignItems="center" gap={4}>
                    {isGroupOwner && (
                        <ButtonSelect
                            value={selectedGroup}
                            onChange={handleGroupChange}
                            options={[allGroupsOption, ...allGroups]}
                            isLoading={isLoading}
                        />
                    )}

                    {/* <MultiSelectFilter
                        label={"Channel"}
                        items={[...allChannels]}
                        filterList={selectedChannels}
                        onChange={(value) => setSelectedChannels(value)}
                    /> */}

                    {/* <MultiSelectFilter
                        label={t("common.branch")}
                        items={[...allBranches]}
                        onChange={(value) => setSelectedChannels(value)}
                    /> */}

                    {/* <ExportButton
                        overviewPDF={{
                            ...overviewPDF,
                            isSingleChannelSelected,
                            isArabic,
                            printDate: `${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`,
                        }}
                        pageName="aggregatorsPDF"
                        isLoading={isLoading}
                    /> */}
                </Box>
            </Box>

            <Grid container spacing={0} flexWrap="nowrap">
                <GridCard
                    fullWidth
                    height={336}
                    padding={0}
                    gridProps={{
                        marginRight: isArabic ? 0 : 6,
                        marginLeft: isArabic ? 6 : 0,
                    }}
                >
                    <PLTable />
                </GridCard>
            </Grid>
        </>
    );
};

export { PL };
