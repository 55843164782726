import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
    AreaChart as BaseComponent,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ReferenceArea,
    Label,
} from "recharts";
import { CurrencyIcon } from "../../../../common/components/Currency/CurrencyIcon";

const CustomizedXAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
        <text
            x={x}
            y={y}
            dy={10}
            textAnchor="middle"
            fill="#202020"
            fontSize={12}
        >
            {payload.value}
        </text>
    );
};
const CustomizedYAxisTick = (props) => {
    const { t } = useTranslation();
    const { x, y, payload } = props;
    return (
        <text
            x={x}
            y={y}
            dx={-25}
            textAnchor="middle"
            fill="#202020"
            fontSize={12}
            fontWeight="bold"
        >
            <CurrencyIcon /> {Math.ceil(payload.value / 1000)}k
        </text>
    );
};

const CustomToolTip = ({ active, payload, label }) => {
    const { t } = useTranslation();
    if (active && payload && payload.length && !payload[0]?.payload?.balance) {
        return (
            <Stack
                padding={5}
                textAlign="center"
                backgroundColor="#FFFFFF"
                borderRadius={2}
                spacing={1}
                // border="none"
                className="custom-tooltip"
                outline="none"
            >
                <Typography
                    variant="body2"
                    gutterBottom
                    fontWeight="bold"
                    align="left"
                    color="#818992"
                >
                    {payload[0].payload?.name}
                </Typography>

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                >
                    <Typography variant="body2">{payload[0]?.name}</Typography>
                    <Typography variant="body2" fontWeight="bold">
                        {Math.abs(+payload[0]?.value)} <CurrencyIcon />
                    </Typography>
                </Box>

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                >
                    <Typography variant="body2">
                        {payload[1] && payload[1]?.name}{" "}
                    </Typography>

                    <Typography variant="body2" fontWeight="bold">
                        {payload[1] && Math.abs(+payload[1]?.value)}{" "}
                        {payload[1] && <CurrencyIcon />}
                    </Typography>
                </Box>
            </Stack>
        );
    }
};

const AreaChart = ({
    data,
    isForcastOnly,
    key1 = "uv",
    key2 = "pv",
    outliers = [],
    handleAddOutlier = () => {},
}) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BaseComponent
                width={500}
                height={400}
                data={data}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                <defs>
                    {/* Define a gradient for the fill */}
                    <linearGradient
                        id="colorUvFill"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                    >
                        <stop
                            offset="5%"
                            stopColor="#14C594"
                            stopOpacity={0.8}
                        />
                        <stop
                            offset="95%"
                            stopColor="#14C594"
                            stopOpacity={0}
                        />
                    </linearGradient>
                </defs>
                <CartesianGrid fill="#FCFCFC" stroke="#EEEFF4" />
                <XAxis
                    dataKey="name"
                    tickLine={false}
                    tickSize={16}
                    fontSize="12px"
                    stroke="#EEEFF4"
                    tick={<CustomizedXAxisTick />}
                />
                <YAxis
                    tickLine={false}
                    fontSize="12px"
                    fontWeight="bold"
                    stroke="#EEEFF4"
                    tick={<CustomizedYAxisTick />}
                />
                <Tooltip
                    content={<CustomToolTip />}
                    wrapperStyle={{ outline: "none" }}
                />
                {!isForcastOnly && (
                    <Area
                        type="monotone"
                        dataKey={key2}
                        stroke="#14C594"
                        fill="url(#colorUvFill)"
                        strokeWidth={2}
                        onClick={(data, index) => handleAddOutlier(data, index)}
                        cursor="pointer"
                    />
                )}
                <Area
                    type="monotone"
                    dataKey={key1}
                    stroke="#DFDE7F"
                    fill="transparent"
                    strokeWidth={2}
                    onClick={(data, index) => handleAddOutlier(data, index)}
                    cursor="pointer"
                />

                {outliers?.map((outlier) => (
                    <ReferenceArea
                        x1={outlier.from}
                        x2={outlier.to}
                        stroke="#5BCFDE"
                        fill="#5BCFDE"
                        strokeWidth={0}
                        fillOpacity={0.2}
                        strokeOpacity={0.3}
                    />
                ))}

                <Label
                    value="Eid Adha"
                    position="insideTop"
                    contentStyle={{
                        backgroundColor: "white",
                        padding: 5,
                        border: "1px solid #888",
                    }}
                />
            </BaseComponent>
        </ResponsiveContainer>
    );
};

export { AreaChart };
