import { Box, Button, Stack } from "@mui/material";
import { useState } from "react";
import { Products } from "./Products/Products";
import { useTranslation } from "react-i18next";
import { Insights } from "./Insights/Insights";
import { useSearchParams } from "react-router-dom";

export const MenuEngineeringPage = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const activeTab = searchParams.get("tab") || "menu";
    const [selectedPage, setSelectedPage] = useState(activeTab);

    return (
        <Stack padding={8} paddingTop={0} spacing={0} minWidth="100%">
            <Stack>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    padding={0}
                    height={100}
                >
                    <Box display="flex" alignItems="baseline" gap={4}>
                        <Button
                            sx={{
                                color: selectedPage === "menu" && "#00C08B",
                                backgroundColor:
                                    selectedPage === "menu" && "#EFFEF4",
                                "&:hover": {
                                    backgroundColor: "#EFFEF4",
                                    color: "#00C08B",
                                },
                                textTransform: "capitalize",
                                // backgroundColor: "#5BCFDE",
                                borderRadius: "8px",
                            }}
                            onClick={() => {
                                setSearchParams({ tab: "menu" });

                                setSelectedPage("menu");
                            }}
                        >
                            {t("menu.insights")}
                        </Button>
                        <Button
                            sx={{
                                color: selectedPage === "products" && "#00C08B",
                                backgroundColor:
                                    selectedPage === "products" && "#EFFEF4",
                                "&:hover": {
                                    backgroundColor: "#EFFEF4",
                                },
                                textTransform: "capitalize",
                                borderRadius: "8px",
                            }}
                            onClick={() => {
                                setSearchParams({ tab: "products" });
                                setSelectedPage("products");
                            }}
                        >
                            {t("sales.products")}
                        </Button>
                    </Box>
                </Box>

                {selectedPage === "menu" && <Insights />}

                {selectedPage === "products" && <Products />}
            </Stack>
        </Stack>
    );
};
