import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Stack, Typography, Divider } from "@mui/material";
import { sidebarData } from "./SidebarData";
import SidebarItem from "./SidebarItem";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
    const { pathname } = useLocation();
    const isInBallurhAssistant = pathname.includes("assistant");

    const { t } = useTranslation(); // Hook for translations
    const location = useLocation();

    return (
        <Stack
            bgcolor="#06262D"
            minHeight={!isInBallurhAssistant && "100vh"}
            maxHeight={isInBallurhAssistant && "100vh"}
            overflow={isInBallurhAssistant && "scroll"}
            minWidth="269px"
            paddingX={2}
            paddingTop={4}
        >
            {/* Logo */}
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginBottom={12}
            >
                <img
                    src="/logo_extend.svg"
                    alt="logo"
                    width="176px"
                    height="31px"
                />
            </Box>

            {/* Navigation Sections */}
            <Box>
                {sidebarData.map((section, index) => (
                    <Box key={index} marginBottom={4}>
                        <Typography
                            variant="h6"
                            color="#FFFFFF"
                            fontWeight="bold"
                            display="flex"
                            alignItems="center"
                            marginBottom={2}
                            fontSize="1.1rem"
                        >
                            {section.icon}
                            {t(section.title)}
                        </Typography>
                        {section.items.map((item, idx) => (
                            <SidebarItem
                                key={idx}
                                label={t(item.label)}
                                path={item.path}
                                isActive={location.pathname === item.path}
                                icon={item.icon}
                                isDisabled={item.isDisabled}
                                iconPath={item.iconPath}
                                isSoon={item.isSoon}
                                isBeta={item.isBeta}
                            />
                        ))}
                        {index < sidebarData.length - 1 && (
                            <Divider
                                sx={{
                                    backgroundColor: "#ffffff22",
                                    marginY: 3,
                                }}
                            />
                        )}
                    </Box>
                ))}
            </Box>
        </Stack>
    );
};

export { Sidebar as SideNavbar };
